export const Info = () => (
  <div id="conferencebuddy">
    <div className="segment-header">
      <h3>I am anxious to go alone!</h3>
    </div>
    <p>
      We are fully aware that an in-person conference can be energy draining.
    </p>
    <p>
      Traveling to an unknown location and meeting many new people is not
      automatically a positive experience for everyone.
    </p>
    <p>
      But our community needs input from participants who are not experienced
      conference attendees or speakers.
    </p>
    <p>
      For this reason, some of our participants volunteer as{' '}
      <a
        href="https://www.conferencebuddy.io/"
        target={'_blank'}
        rel="noreferrer"
      >
        ConferenceBuddies
      </a>
      .
    </p>
    <p>
      They commit to supporting you in attending the conference. They could, for
      example, meet with you before the conference starts, and explore the
      conference venue. Or they will be the person you can always walk up to
      during the conference.
    </p>
    <p>
      Head over to the{' '}
      <a
        href="https://socrates-conference.slack.com/archives/CC91HNGEM"
        target="_blank"
        rel="noreferrer"
      >
        #buddies
      </a>{' '}
      Slack channel and find <strong>your</strong> ConferenceBuddy, or offer to
      be one yourself.
    </p>
  </div>
);
