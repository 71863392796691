import type { NewsletterEvent } from '../../events/newsletterEvents';
import NewsletterEvents from '../../events/newsletterEvents';
import { EMPTY } from '../../newsletter-signout/NewsletterSignOutConstants';
type State = {
  message: string;
};
const INITIAL_STATE = {
  message: EMPTY,
};

const newsletterSignUpReducer = (
  state: State = INITIAL_STATE,
  event: NewsletterEvent,
) => {
  switch (event.type) {
    case NewsletterEvents.ADD_SUCCEEDED:
    case NewsletterEvents.ADD_FAILED:
      return {
        message: event.message || EMPTY,
      };

    default:
      return state;
  }
};

export default newsletterSignUpReducer;
