import React, { createContext, PropsWithChildren, useContext } from 'react';
import { Sponsor } from './sponsoring/sponsoring.types';
import { SponsorsApi } from './api/sponsors';

type SponsorsContext = {
  getSponsors: () => Promise<Sponsor[]>;
};
const defaultSponsors: SponsorsContext = {
  getSponsors: () => Promise.resolve([]),
};
const context = createContext(defaultSponsors);
export const useSponsors = () => useContext(context);

type ProvideSponsorsProps = {
  sponsorsApi: SponsorsApi;
};
export const SponsorsProvider = ({
  sponsorsApi,
  children,
}: PropsWithChildren<ProvideSponsorsProps>) => {
  const sponsors = {
    getSponsors: sponsorsApi.getSponsors,
  };
  return <context.Provider value={sponsors}>{children}</context.Provider>;
};
