import isValidEmailFormat from './isValidEmailFormat';
import { Applicant } from '../view/common/types';

export const validationClassName = (isValid: boolean) =>
  isValid ? 'is-valid' : 'is-invalid';
export const checkboxValidationClassName = (isValid: boolean) =>
  `form-control ${isValid ? 'is-valid' : 'is-invalid'}`;
export const isValidNickname = (nickname: string) => nickname.trim().length > 0;
export const isValidEmail = (email?: string) =>
  email !== undefined && email.trim().length > 0 && isValidEmailFormat(email);
export const validateRoomTypesSelected = (applicant?: Applicant) => {
  const hasSharedRoom: boolean =
    applicant?.roomTypeSelected.some((t) => t.indexOf('shared') >= 0) ?? false;
  return (
    applicant !== undefined &&
    applicant.roomTypeSelected.length > 0 &&
    (!hasSharedRoom ||
      (hasSharedRoom &&
        isValidEmailFormat(applicant.roommate) &&
        applicant.roommate !== applicant.email))
  );
};
