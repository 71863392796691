import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { ConferencesApi } from './api/conferences';
import { Conference } from './types';

type ConferenceContext = {
  conference: () => Promise<Conference | undefined>;
};

const DEFAULT_CONTEXT: ConferenceContext = {
  conference: () => Promise.resolve(undefined),
};

const conferenceContext = createContext(DEFAULT_CONTEXT);
export const useConference = () => useContext(conferenceContext);

type ProvideConferencesProps = {
  conferencesApi: ConferencesApi;
};

export const ConferenceProvider = ({
  conferencesApi,
  children,
}: PropsWithChildren<ProvideConferencesProps>) => {
  const [conf, setConf] = useState<Conference | undefined>();
  const conference = async () => {
    if (!conf) {
      setConf(await conferencesApi.getConference());
    }
    return conf;
  };
  const context = {
    conference,
  };

  return (
    <conferenceContext.Provider value={context}>
      {children}
    </conferenceContext.Provider>
  );
};
