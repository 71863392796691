const formatter = Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});
const toDateOrInvalid = (date: string | undefined) =>
  date ? formatter.format(new Date(date)) : 'Invalid date';

export const eventDate = (
  start: string | undefined,
  end: string | undefined,
) => {
  if (start === undefined || end === undefined) {
    return `${toDateOrInvalid(start)} – ${toDateOrInvalid(end)}`;
  }

  const startDate = new Date(start);
  const endDate = new Date(end);
  return formatter.formatRange(startDate, endDate);
};
