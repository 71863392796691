import React, { ChangeEventHandler, FormEventHandler } from 'react';
import { EmailInput } from '../application/form/EmailInput';

export type Props = {
  email: string;
  hasValidEmail: boolean;
  message: string;
  onEmailChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  isDisabled: boolean;
};

type MessageProps = {
  message?: string;
  className?: string;
};

function Message({ message, className }: MessageProps) {
  return message && message !== '' ? (
    <div
      id="newsletter-message"
      className={'message p-3 mt-3 mb-5 pulse ' + className}
      dangerouslySetInnerHTML={{ __html: message.replaceAll('\n', '<br />') }}
    />
  ) : (
    <div />
  );
}

export default function NewsletterForm(props: Props) {
  const klass = (isValid: boolean) =>
    `form-control mb-2 ${isValid ? 'is-valid' : 'is-invalid'}`;

  const emailClass = klass(props.hasValidEmail);

  return (
    <div>
      <div className="segment-header">
        <h1>Newsletter Sign-Out</h1>
      </div>
      <div>
        <p>At this point you can unsubscribe from the SoCraTes newsletter.</p>
        <p>
          If that is what you want to do, just enter your email address and
          click the <i>Unsubscribe</i> button. From this point on, no more
          newsletters will be sent to the provided e-mail address.
        </p>
      </div>
      <div className={'row'}>
        <div className={'col-3'} />
        <div className={'col-6'}>
          {props.message ? (
            <Message
              message={props.message}
              className={
                props.message.indexOf("We're sorry") > -1
                  ? 'alert-danger'
                  : 'alert-success'
              }
            />
          ) : (
            <form className="form card mt-5 mb-5" onSubmit={props.onSubmit}>
              <div className={'card-body'}>
                <EmailInput
                  id={'newsletter-sign-out-email'}
                  onChange={props.onEmailChange}
                  validationClass={emailClass}
                  value={props.email}
                  placeholderText={'Your email'}
                  helpText={''}
                  labelText={'Email'}
                />
              </div>
              <div className={'card-footer'}>
                <button
                  id="newsletter-sign-out-form-button"
                  className="btn btn-primary"
                  disabled={props.isDisabled}
                  type={'submit'}
                >
                  Unsubscribe
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
