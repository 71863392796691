import axios from 'axios';
import { Conference } from '../types';
import config from '../../../config';

const conferencesApi: string = config.apiEndpoints.conferences;
const conferencesEndpoint: string = `${conferencesApi}/conferences`;

export type ConferencesApi = {
  getConference: () => Promise<Conference | undefined>;
};

export class Conferences implements ConferencesApi {
  private _conference: Conference | undefined;

  public constructor() {
    this._conference = undefined;
  }

  public getConference = async () => {
    this._conference = (
      await axios.get(conferencesEndpoint + '/' + config.conference)
    ).data;
    return this._conference;
  };
}
