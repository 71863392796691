import staticMapUrl from '../../assets/img/Tagungszentrum.jpg';

import React from 'react';
const slackSigninUrl = 'https://socrates-conference.slack.com/';
const cocUrl = '/values#coc';
const trainingFeedbackUrl = 'https://forms.gle/DkjnxCtUoFwiY2iFA';
const feedbackUrl = 'https://forms.gle/b3B9o3YAXFrzdMp88';
const hotelTipUrl = 'https://paypal.me/socratesdeutschland';
const quickLinks = [
  [cocUrl, 'Code of Conduct'],
  [staticMapUrl, 'Static Hotel Park map (jpg)'],
  [slackSigninUrl, 'Sign in to SoCraTes Slack'],
  [trainingFeedbackUrl, 'Training day session feedback'],
  [feedbackUrl, 'Give us some general feedback ❤'],
  [hotelTipUrl, 'Tip the hotel (PayPal) 🙏'],
];

export default function OnsiteQuickLinks() {
  return (
    <div id="onsite-quick-links" className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="page-header">
            <h2>Quick links to helpful onsite information</h2>
          </div>
        </div>
      </div>
      <ul>
        {quickLinks.map(([l, t], i) => {
          return (
            <li key={i}>
              <h5>
                <a href={l} target="_blank" rel="noopener noreferrer">
                  {t}
                </a>
              </h5>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
