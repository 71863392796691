import React, { ChangeEventHandler } from 'react';
import { RoomType } from '../../common/types';
import { EmailInput } from './EmailInput';
type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onRoommateChange: ChangeEventHandler<HTMLInputElement>;
  options?: RoomType[];
  roommate?: string;
  selected: Array<string>;
  validationClass?: string;
};

export function RoomTypeInput({
  onRoommateChange,
  onChange,
  options = [],
  roommate,
  selected,
  validationClass,
}: Props) {
  const hasOptions = options.length > 0;
  return hasOptions ? (
    <div className={'m-0 p-0'}>
      <div className="form-group col">
        <label htmlFor="roomType" className="col-form-label">
          Room Type
        </label>
        <div className="table-responsive">
          {options.map(({ type, description, pricePerNight }) => (
            <div key={type} className="radio-inline row">
              <label className={'col'}>
                <input
                  checked={selected.includes(type)}
                  id={type}
                  name="roomType"
                  type="checkbox"
                  value={type}
                  onChange={onChange}
                />
                <b>&nbsp;{description}</b>
                <small>&nbsp;({pricePerNight} per night)</small>
              </label>
            </div>
          ))}
        </div>
      </div>
      {options
        .filter((o) => o.type.indexOf('shared') >= 0)
        .some((o) => selected.includes(o.type)) && (
        <EmailInput
          className={'form-group col roommate'}
          onChange={onRoommateChange}
          helpText={
            "We'll never share email addresses with third parties. \n" +
            "Please note that for obvious reasons, we can only ever assign shared rooms, if both roommates are registered, and mutually provide each other's email."
          }
          labelText={"Your Roommate's Email"}
          placeholderText={"Please provide your desired roommate's email"}
          validationClass={validationClass ?? ''}
          value={roommate ?? ''}
        />
      )}
    </div>
  ) : (
    <div />
  );
}
