import React from 'react';

export function ExclusionAnnouncement() {
  return (
    <div className="announcement-container">
      <div>
        <h4>Announcement</h4>
        <label>
          <div className="announcement-paragraph">
            The SoCraTes organisers have decided to exclude employees of
            Palantir Technologies Inc., from participating in the SoCraTes
            conference. Please do not apply for a ticket, if you belong to this
            group.
          </div>
          <div className="announcement-paragraph">
            Palantir provides technology to US Immigration and Customs
            Enforcement. This technology is used to target raids on immigrants,
            who are then detained in concentration camps. In these camps,
            children are often held separately from their parents for long
            periods of time, which constitutes genocide under article 2d of the
            UN Convention on Genocide.
          </div>
          <div>
            Participating in acts of cruelty is incompatible with the values of
            this conference. While it is possible to argue ad nauseam about
            where to draw the line, this is one line the organisers are willing
            to draw for now.
          </div>
        </label>
      </div>
    </div>
  );
}
