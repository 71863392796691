import { Component, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
type Props = RouteComponentProps & {
  location: Location;
  children: ReactNode | [];
};

class ScrollToTop extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (
      (!this.props.location.hash || this.props.location.hash === '') &&
      this.props.location !== prevProps.location
    ) {
      window.scrollTo(0, 0);
    } else if (this.props.location.hash !== '') {
      const id = this.props.location.hash.replace('#', '');
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView();
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
