import './PartnerBadge.scss';

export const PartnerBadge = () => (
  <div id="conferencebuddy">
    <div className="page-header">
      <h2>Inclusivity Partner</h2>
    </div>
    <p className="logo">
      <a href="/home#conferencebuddy">
        <img src="/ConferenceBuddy.webp" alt="ConferenceBuddy" />
      </a>
    </p>
    <p>
      <em>
        Conference Buddy is for everyone who wants to attend a tech conference
        but is afraid to go alone.
      </em>
    </p>
    <p>
      Find <strong>your</strong> ConferenceBuddy, or offer to be one in the{' '}
      <a
        href="https://socrates-conference.slack.com/archives/CC91HNGEM"
        target="_blank"
        rel="noreferrer"
      >
        #buddies
      </a>{' '}
      Slack channel. <a href="/home#conferencebuddy">Learn more.</a>
    </p>
  </div>
);
