const renderToConsole = (obj: any, rightArrow: any) => {
  let formatted = '';
  Object.keys(obj).forEach((key) => {
    if (key.length > 0) {
      formatted += `${rightArrow} ${key} `;
    }

    if (obj.hasOwnProperty(key)) {
      formatted += `${JSON.stringify(obj[key])}\n`;
    }
  });
  return formatted;
};

const downArrow = '▼';
const rightArrow = '▶';
const logger: () => (
  store: any,
) => (next: any) => (action: any) => any = () => {
  return (store: any) => (next: any) => (action: any) => {
    const isDebugMode = process.env.DEBUG && process.env.DEBUG === 'true';

    if (!isDebugMode || action.type === '@@RESET_TESTER') {
      return next(action);
    }

    const { getState } = store;
    const prevState = renderToConsole(getState(), rightArrow);
    const actionValue = renderToConsole(action, rightArrow);
    // eslint-disable-next-line
    const returnValue = next(action)
    const nextState = renderToConsole(getState(), rightArrow);
    const time = new Date();
    const message = `${downArrow} action ${action.type} @ ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`;
    const output = `${message}\n  prev state\n${prevState}\n  action\n${actionValue}\n  next\n${nextState}`;
    console.log(output);
    return returnValue;
  };
};
export default logger;
