import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { createBrowserHistory, History } from 'history';
import { createMemoryHistory } from 'history';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import createTestLogger from '../../test/logger';
export class HistoryFactory {
  static createHistory(): History {
    return createBrowserHistory({
      // @ts-ignore
      basename: '/',
    });
  }

  static createTestHistory() {
    return createMemoryHistory();
  }
}
export default class StoreFactory {
  static _createStore(reducers: any, rootSaga: any, history: any, logger: any) {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = applyMiddleware(
      sagaMiddleware,
      routerMiddleware(history),
      logger,
    );
    const allReducers = Object.assign(reducers, {
      router: routerReducer,
    });
    const store = createStore(
      combineReducers(allReducers),
      compose(middlewares),
    );
    sagaMiddleware.run(rootSaga);
    return store;
  }

  static createStore(reducers: any, rootSaga: any, history: any) {
    return this._createStore(
      reducers,
      rootSaga,
      history,
      createLogger({
        collapsed: true,
      }),
    );
  }

  static createTestStore(reducers: any, rootSaga: any, history: any) {
    return this._createStore(reducers, rootSaga, history, createTestLogger());
  }
}
