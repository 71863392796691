import {
  REMOVE_FAILED,
  REMOVE_SUCCEEDED,
} from '../newsletter-signout/NewsletterSignOutConstants';
import {
  ADD_FAILED,
  ADD_SUCCEEDED,
} from '../home/newsletter/NewsletterSignUpConstants';
import {
  CONFIRM_FAILED,
  CONFIRM_SUCCEEDED,
} from '../newsletter-confirm/NewsletterConfirmConstants';
export type NewsletterEvent = {
  type: string;
  message?: string;
};
export default class NewsletterEvents {
  static get ADD_SUCCEEDED() {
    return 'Events/NEWSLETTER_ADD_SUCCEEDED';
  }

  static get ADD_FAILED() {
    return 'Events/NEWSLETTER_ADD_FAILED';
  }

  static get REMOVE_SUCCEEDED() {
    return 'Events/NEWSLETTER_REMOVE_SUCCEEDED';
  }

  static get REMOVE_FAILED() {
    return 'Events/NEWSLETTER_REMOVE_FAILED';
  }

  static get CONFIRM_SUCCEEDED() {
    return 'Events/NEWSLETTER_CONFIRM_SUCCEEDED';
  }

  static get CONFIRM_FAILED() {
    return 'Events/NEWSLETTER_CONFIRM_FAILED';
  }
}
export const addSucceeded = () => ({
  type: NewsletterEvents.ADD_SUCCEEDED,
  message: ADD_SUCCEEDED,
});
export const addFailed = () => ({
  type: NewsletterEvents.ADD_FAILED,
  message: ADD_FAILED,
});
export const removeSucceeded = () => ({
  type: NewsletterEvents.REMOVE_SUCCEEDED,
  message: REMOVE_SUCCEEDED,
});
export const removeFailed = () => ({
  type: NewsletterEvents.REMOVE_FAILED,
  message: REMOVE_FAILED,
});
export const confirmSucceeded = () => ({
  type: NewsletterEvents.CONFIRM_SUCCEEDED,
  message: CONFIRM_SUCCEEDED,
});
export const confirmFailed = () => ({
  type: NewsletterEvents.CONFIRM_FAILED,
  message: CONFIRM_FAILED,
});
