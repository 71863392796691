import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EMPTY } from './NewsletterConfirmConstants';
import NewsletterConfirmForm from './NewsletterConfirmForm';
import { RouteComponentProps, withRouter } from 'react-router';
import { confirm } from '../commands/newsletterCommand';
export type Props = RouteComponentProps & {
  match: {
    params: {
      data: string;
    };
  };
  message?: string;
  confirm?: (email: string) => {};
};
export type State = {
  consentKey: string;
  isDataPrivacyConfirmedChecked: boolean;
  isNewsletterConfirmedChecked: boolean;
  isSubmitting: boolean;
  message: string;
};
const initialState = {
  consentKey: EMPTY,
  isDataPrivacyConfirmedChecked: false,
  isNewsletterConfirmedChecked: false,
  isSubmitting: false,
  message: EMPTY,
};
export class NewsletterConfirmContainer extends Component<Props, State> {
  state = initialState;

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.message) {
      return { ...prevState, ...nextProps, isSubmitting: false };
    } else if (hasParams(nextProps)) {
      const consentKey = nextProps.match.params.data;
      return { ...prevState, consentKey, isSubmitting: false };
    } else {
      return prevState;
    }
  }

  onSubmit = () => {
    this.setState({ ...this.state, isSubmitting: true, message: EMPTY }, () => {
      this.props.confirm && this.props.confirm(this.state.consentKey);
    });
  };
  onConsentKeyChange = (consentKey: string) => {
    this.setState({ ...this.state, consentKey });
  };
  onNewsletterConfirmedChange = () => {
    this.setState({
      ...this.state,
      isNewsletterConfirmedChecked: !this.state.isNewsletterConfirmedChecked,
    });
  };
  onDataPrivacyConfirmedChange = () => {
    this.setState({
      ...this.state,
      isDataPrivacyConfirmedChecked: !this.state.isDataPrivacyConfirmedChecked,
    });
  };

  render() {
    const {
      isSubmitting,
      message,
      consentKey,
      isNewsletterConfirmedChecked,
      isDataPrivacyConfirmedChecked,
    } = this.state;
    const isDisabled =
      consentKey === '' ||
      isSubmitting ||
      !isNewsletterConfirmedChecked ||
      !isDataPrivacyConfirmedChecked;
    return (
      <div className="container">
        <div className="row">
          <div id="newsletter">
            <NewsletterConfirmForm
              consentKey={consentKey}
              message={message}
              onConsentKeyChange={this.onConsentKeyChange}
              onSubmit={this.onSubmit}
              isDisabled={isDisabled}
              isNewsletterConfirmedChecked={isNewsletterConfirmedChecked}
              isDataPrivacyConfirmedChecked={isDataPrivacyConfirmedChecked}
              newsletterConfirmedChange={this.onNewsletterConfirmedChange}
              dataPrivacyConfirmedChange={this.onDataPrivacyConfirmedChange}
              email=""
              hasValidEmail={true}
              onEmailChange={() => {}}
              onNameChange={() => {}}
            />
          </div>
        </div>
      </div>
    );
  }
}

const hasParams = (props: Props) =>
  props.match && props.match.params && Boolean(props.match.params.data);

const mapStateToProps = (state: any) => ({ ...state.newsletter });

const mapDispatchToProps = {
  confirm,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewsletterConfirmContainer),
);
