import { Applicant } from '../types';
import config from '../../../config';
import axios from 'axios';
import {
  AlreadyRegisteredException,
  BadRequestException,
  InternalErrorException,
  NotFoundException,
} from './error';

const applicantsApi: string = config.apiEndpoints.applicants;
const applicantsEndpoint: string = `${applicantsApi}/applicants`;

export interface ApplicantsApi {
  addApplicant: (applicant: Applicant) => Promise<void>;
  withdraw: (email: string) => Promise<void>;
}

const postApplicant = async (created: Applicant): Promise<void> => {
  await axios.post(`${applicantsEndpoint}`, created);
};

const deleteApplicantByEmail = async (email: string): Promise<void> => {
  await axios.delete(
    `${applicantsEndpoint}/email/${config.conference}/${email}`,
  );
};

export class Applicants implements ApplicantsApi {
  public async addApplicant(applicant: Applicant): Promise<void> {
    const toPut: Applicant = { ...applicant };
    toPut.index = undefined;
    delete toPut.index;
    await postApplicant(applicant).catch((e) => {
      console.error(e);
      if (e.message.endsWith('409')) {
        throw new AlreadyRegisteredException();
      } else if (e.message.endsWith('400')) {
        throw new BadRequestException();
      } else throw new InternalErrorException();
    });
  }
  public async withdraw(email: string): Promise<void> {
    await deleteApplicantByEmail(email).catch((e) => {
      console.error(e);
      if (e.message.endsWith('404')) {
        throw new NotFoundException();
      } else if (e.message.endsWith('400')) {
        throw new BadRequestException();
      } else throw new InternalErrorException();
    });
  }
}
