import React from 'react';
import { LogoColumn } from '../common/LogoColumn';
import { useRouteMatch } from 'react-router-dom';
import { AbstractKey, abstracts } from './abstracts';

export default function Abstract() {
  const {
    params: { abstract },
  } = useRouteMatch<{ abstract: AbstractKey }>();

  return (
    <div id="training" className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-header"></div>
        </div>
        <div className="row  m-2">
          <div className="col-md-8  mt-4">
            <div className="row">
              <h2 className="mt-5">
                &raquo; {abstracts[abstract].title} &laquo;
              </h2>
            </div>
            <div className="row">
              <h3
                className="mt-5 fst-italic"
                dangerouslySetInnerHTML={{ __html: abstracts[abstract].author }}
              ></h3>
            </div>
            <div>
              <div className="row">
                <hr />
                <h3 className="mt-5">Abstract</h3>
              </div>
              <div className="row mt-2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: abstracts[abstract].abstract,
                  }}
                ></div>
              </div>
            </div>
            {abstracts[abstract].bio.length !== 0 && (
              <div>
                <div className="row">
                  <h3 className="mt-5">Bio</h3>
                </div>
                <div className="row mt-2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: abstracts[abstract].bio,
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
          <LogoColumn />
        </div>
      </div>
    </div>
  );
}
