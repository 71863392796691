import React from 'react';
import SponsorComponent from './SponsorComponent';
import type { Sponsor } from './sponsoring.types';
import Separator from './Separator';
type Props = {
  sponsors: Sponsor[];
};
export default function SponsorRow({ sponsors }: Props) {
  return (
    <div>
      <div className="row">
        {sponsors.map((s, index) => (
          <SponsorComponent
            key={index}
            url={s.url}
            name={s.name}
            logo={s.logo}
          />
        ))}
      </div>
      <Separator />
    </div>
  );
}
