import React from 'react';
import './description.scss';
import Nutshell from './Nutshell';
import Address from './Address';
import Environment from './Environment';
import { Info as ConferenceBuddyInfo } from '../../common/ConferenceBuddy/Info';
import Covid from './Covid';

export default function SocratesDescription() {
  return (
    <div id="socrates-description">
      <Address />
      <Nutshell />
      <Covid />
      <Environment />
      <ConferenceBuddyInfo />
    </div>
  );
}
