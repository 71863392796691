export enum MessageType {
  ALREADY_APPLIED = 'ALREADY_APPLIED',
  BAD_REQUEST = 'BAD_REQUEST',
  EMPTY = 'EMPTY',
  FAIL = 'FAIL',
  NOT_FOUND = 'NOT_FOUND',
  REGISTRATION_CLOSED = 'REGISTRATION_CLOSED',
  SUCCESS = 'SUCCESS',
  WITHDRAW_SUCCESS = 'WITHDRAW_SUCCESS',
}
export const MESSAGES: Record<string, string> = {
  ALREADY_APPLIED:
    "We're sorry, an application for this email already exists.\n\nThe good news is: You don't have to do anything - you're already in the lottery.\nUnless you would like to change your data - in which case, please send an email to registration@socrates-conference.de instead.",
  BAD_REQUEST:
    "We're sorry, unfortunately, we were unable to process your request.\n\nYou entered something we could not understand. Please try again.",
  EMPTY: '',
  FAIL: "We're sorry, unfortunately, the api seems to be unreachable.\n\nPlease try again later.",
  NOT_FOUND:
    "We're sorry, unfortunately, we couldn't identify the email you entered. Please check that your entry is correct.",
  REGISTRATION_CLOSED:
    "We're sorry, the registration is currently closed.\n\nSign up to our newsletter to stay informed, or follow us on LinkedIn or Mastodon!",
  SUCCESS:
    'Thank you for caring about good software.\n\nYou are now taking part in the lottery for SoCraTes. Good Luck!',
  WITHDRAW_SUCCESS:
    "You have successfully withdrawn your application to the SoCraTes lottery.\n\nWe're sad to see you go. Please consider applying again next year!",
};
