import React from 'react';

const Address = () => (
  <div id="address">
    <div className="page-header">
      <h2>All set for a new year</h2>
      <div className="segment-header">
        <div>
          <p>Dear friends of SoCraTes,</p>
        </div>
        <div>
          <p>
            Last year's edition was once again amazing! And we are working hard
            to make this year's edition even better...
          </p>
          <p>Stay tuned for upcoming announcements!</p>
          <p>
            Because as always, a lot of things are happening "as we go along",
            please sign up for our newsletter below to stay updated!
          </p>
        </div>
        <div>
          <p>
            In the meantime, if you have any questions or concerns you can
            contact the organisers:{' '}
            <a href="mailto:info@socrates-conference.de">
              info@socrates-conference.de
            </a>
            .
          </p>
        </div>
        <div>
          <p>Your organising team</p>
        </div>
      </div>
    </div>
  </div>
);
export default Address;
