import React from 'react';

export function DiversityDescription() {
  return (
    <div className="diversity-info text-muted form-text">
      <p>
        To increase the diversity of SoCraTes, we have a quota of rooms set
        aside for underrepresented groups in tech and/or SoCraTes.
      </p>
      <p>We consider the following groups underrepresented in that sense:</p>
      <ul>
        <li>Women in tech</li>
        <li>People of Color</li>
        <li>LGBTQIA+ folk</li>
        <li>People from abroad</li>
        <li>Disabled people</li>
        <li>Neurodivergent people</li>
        <li>Financially disadvantaged people</li>
      </ul>
    </div>
  );
}
