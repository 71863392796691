import React from 'react';
import PartnerConferenceComponent from './PartnerConferenceComponent';
import type { PartnerConference } from './partnerConferences.types';
import './conferences.scss';
type Props = {
  conferences: PartnerConference[];
};
export default function PartnerConferences({ conferences }: Props) {
  return (
    <div id="conferences" className="conferences">
      <div className="row">
        <div className="col-sm-12">
          <hr />
          <h4>Partner Conferences</h4>
        </div>
      </div>
      <div className="row">
        {conferences.map((conf) => (
          <div className="conference col-sm-6" key={conf.name}>
            <PartnerConferenceComponent {...conf} />
          </div>
        ))}
      </div>
    </div>
  );
}
