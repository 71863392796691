import './faq.scss';
import React from 'react';
import FAQItem from './FAQItem';
import faq from './faqItems';
import { LogoColumn } from '../common/LogoColumn';
export default function FAQ() {
  return (
    <div id="faq" className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="page-header">
            <h1>FAQ</h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div id="faq-accordion" className="col-md-8">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header">
                <h3>FAQ</h3>
              </div>
            </div>
          </div>
          {faq.map((item, index) => (
            <FAQItem
              title={item.title}
              content={item.content}
              index={index}
              key={`faq-item-${index}`}
            />
          ))}
        </div>
        <LogoColumn />
      </div>
    </div>
  );
}
