import hotelPicture from '../../assets/img/soltau-inner-yard.jpg';
import React from 'react';
import {
  hotelGoogleMaps,
  trainFromBerlinCentralStation,
  trainFromFrankfurtCentralStation,
  trainFromHamburgAirportUrl,
  trainFromHamburgCentralStationUrl,
} from './locationUrls';
// @ts-expect-error
import pdfMapPath from './map.pdf';
import { LogoColumn } from '../common/LogoColumn';
export default function Location() {
  return (
    <div id="location" className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-header">
            <h1>Location</h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="row">
            <div className="col-xs-12">
              <div className="page-header">
                <h2>Hotel Park Soltau</h2>
              </div>
              <div>
                <p> Out in the country, approximately one hour from Hamburg.</p>
                <p>
                  It offers great facilities for community-centered events with
                  included accommodation. We have rented the entire facility, so
                  we can concentrate on what we like to do most.
                </p>
                <p>
                  <a
                    href="http://www.hotel-park-soltau.de/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The hotel&#39;s website (German only)
                  </a>
                </p>
                <p>
                  <a href={pdfMapPath}>Rooms and hotel map</a>
                </p>
              </div>
              <div className="segment-header">
                <h3>Directions to SoCraTes Venue</h3>
              </div>
              <div>
                <p>
                  The address:
                  <strong>
                    {' '}
                    Hotel Park Soltau, Winsener Str. 111, 29614 Soltau
                  </strong>
                </p>
                <h4>By Train</h4>
                <p>Here are several predefined trips that might suit you:</p>
                <ul>
                  <li>
                    <a
                      href={trainFromHamburgAirportUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      From Hamburg Airport
                    </a>
                  </li>
                  <li>
                    <a
                      href={trainFromHamburgCentralStationUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      From Hamburg central station{' '}
                    </a>
                  </li>
                  <li>
                    <a
                      href={trainFromFrankfurtCentralStation}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      From Frankfurt central station{' '}
                    </a>
                  </li>
                  <li>
                    <a
                      href={trainFromBerlinCentralStation}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      From Berlin central station{' '}
                    </a>
                  </li>
                </ul>
                <p>
                  If you are searching for a connection yourself, be sure to
                  give<strong>&#34;Soltau Nord&#34;</strong>
                  as the destination station. It&#39;s the railway station
                  that&#39;s right next to the venue.
                </p>
                <p>
                  There is another station called &#34;Soltau&#34; which is
                  serviced more frequently but which may make a taxi ride
                  necessary. Please check Google Maps for distances.
                </p>
                <p>
                  The portal of the german railway is at{' '}
                  <a
                    href="https://int.bahn.de/en/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    bahn.de and in english
                  </a>
                  .{' '}
                </p>
                <h4>By Car</h4>
                <p>
                  <a
                    href={hotelGoogleMaps}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Try searching for direction on Google Maps{' '}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="segment-header">
            <h2>Atmosphere</h2>
          </div>
          <div>
            <img
              className="img-fluid"
              src={hotelPicture}
              alt="Soltau inner yard"
            />
          </div>
          <div>
            <p>We prepare a great stage for you. 24 hours a day.</p>
            <p>
              {' '}
              You will definitely not only hang around in the meeting rooms.
              Since we have rented most of the facility there will be many
              places to meet and discuss in a relaxed way. Enjoy the air, the
              sun, the landscape.{' '}
            </p>
          </div>
          <div className="segment-header">
            <h3>Rooms</h3>
          </div>
          <div>
            <p>
              Some of the venue&#39;s rooms are twin rooms. An important aspect
              of SoCraTes is its closedness, where everything happens in the
              same location. Therefore, we can only accept as many participants
              as there are beds available.{' '}
            </p>
            <p>
              When you register, you can let us know whom you&#39;d be willing
              to share a room with. Since Corona, we currently do not offer
              support to find people to share a room with. After registration
              you&#39;ll be able to access the SoCraTes Slack, where you can get
              to know other participants before the conference, share
              information about Taxi- and Ride sharing and other organisational
              matters.{' '}
            </p>
          </div>
        </div>
        <LogoColumn />
      </div>
    </div>
  );
}
