import client from './client';
import config from '../../config';

const apiEndpoint = config.apiEndpoints.subscriptions;
const subscriptions = apiEndpoint + '/subscribers';
const addInterestedPerson = (name: string, email: string): Promise<boolean> =>
  client.post(subscriptions, {
    name,
    email,
  });

const confirmInterestedPerson = (consentKey: string): Promise<boolean> =>
  client.put(subscriptions, {
    consentKey,
  });

const removeInterestedPerson = (email: string): Promise<boolean> =>
  client.delete(subscriptions + '/' + email);

const api = {
  addInterestedPerson,
  confirmInterestedPerson,
  removeInterestedPerson,
};

export default api;
