import React from 'react';
import PartnerConferences from './partnerConferences/PartnerConferences';
import SocratesDescription from './socratesDescription/SocratesDescription';
import Newsletter from './newsletter/NewsletterSignUpContainer';
import './home.scss';
import { LogoColumn } from '../common/LogoColumn';
import shuffleArray from '../../utils/shuffleArray';
import { conferences } from './partner-conferences';

export function Home() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-8">
          <div className="row column-left">
            <div className="col-xs-12">
              <SocratesDescription />
            </div>
          </div>
          <div className="row column-left">
            <div className="col-xs-12">
              <Newsletter signUp={() => {}} />
            </div>
          </div>
          <div className="row column-left">
            <div className="col-xs-12">
              <PartnerConferences conferences={shuffleArray(conferences)} />
            </div>
          </div>
        </div>
        <LogoColumn />
      </div>
    </div>
  );
}
