import React, { useEffect, useState } from 'react';
import Sponsors from './Sponsors';
import './sponsoring.scss';
import { Link } from 'react-router-dom';
import shuffleArray from '../../../utils/shuffleArray';
import { Sponsor } from './sponsoring.types';
import { useSponsors } from '../SponsorsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';

export default function Sponsoring() {
  const [sponsors, setSponsors] = useState<Sponsor[]>([]);
  const api = useSponsors();
  useEffect(() => {
    api.getSponsors().then((s) => setSponsors(s));
  }, [api]);
  return (
    <div id="sponsoring">
      <Sponsors
        title="Our Sponsors"
        showEmpty={true}
        sponsors={shuffleArray(sponsors) as unknown as Sponsor[]}
      />
      <Link to="/sponsoring" className="btn btn-info pull-right">
        <FontAwesomeIcon icon={faThumbsUp} /> Sponsor us{' '}
      </Link>
    </div>
  );
}
