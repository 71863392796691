import React from 'react';
type LinkProps = {
  url: string;
  target: string;
  title: string;
  children: any;
  className?: string;
};
export default function ExternalLink({
  url,
  title,
  target = '_self',
  className,
  children,
}: LinkProps) {
  return (
    <a href={url} title={title} target={target} className={className}>
      {children}
    </a>
  );
}
