/* eslint-disable */
export const trainFromHamburgAirportUrl =
  'https://int.bahn.de/en/buchung/fahrplan/suche#sts=false&so=Hamburg%20Airport&zo=Soltau%20Nord&kl=2&r=13:16:KLASSENLOS:1&soid=A%3D1%40O%3DHamburg%20Airport%40X%3D10006648%40Y%3D53632350%40U%3D80%40L%3D8002547%40B%3D1%40p%3D1718651605%40i%3DU%C3%97008085471%40&zoid=A%3D1%40O%3DSoltau%20Nord%40X%3D9861014%40Y%3D53001361%40U%3D80%40L%3D8005594%40B%3D1%40p%3D1718651605%40i%3DU%C3%97008001017%40&sot=ST&zot=ST&soei=8002547&zoei=8005594&hd=2024-08-22T09:30:11&hza=D&hz=%5B%5D&ar=false&s=true&d=false&vm=00,01,02,03,04,05,06,07,08,09&fm=false&bp=false';
export const trainFromHamburgCentralStationUrl =
  'https://int.bahn.de/en/buchung/fahrplan/suche#sts=false&so=Hamburg%20Hbf&zo=Soltau%20Nord&kl=2&r=13:16:KLASSENLOS:1&soid=A%3D1%40O%3DHamburg%20Hbf%40X%3D10006909%40Y%3D53552733%40U%3D80%40L%3D8002549%40B%3D1%40p%3D1718651605%40i%3DU%C3%97008001071%40&zoid=A%3D1%40O%3DSoltau%20Nord%40X%3D9861014%40Y%3D53001361%40U%3D80%40L%3D8005594%40B%3D1%40p%3D1718651605%40i%3DU%C3%97008001017%40&sot=ST&zot=ST&soei=8002549&zoei=8005594&hd=2024-08-22T09:30:11&hza=D&hz=%5B%5D&ar=false&s=true&d=false&vm=00,01,02,03,04,05,06,07,08,09&fm=false&bp=false';
export const trainFromFrankfurtCentralStation =
  'https://int.bahn.de/en/buchung/fahrplan/suche#sts=false&so=Frankfurt(Main)Hbf&zo=Soltau%20Nord&kl=2&r=13:16:KLASSENLOS:1&soid=A%3D1%40O%3DFrankfurt(Main)Hbf%40X%3D8663785%40Y%3D50107149%40U%3D80%40L%3D8000105%40B%3D1%40p%3D1718651605%40i%3DU%C3%97008011068%40&zoid=A%3D1%40O%3DSoltau%20Nord%40X%3D9861014%40Y%3D53001361%40U%3D80%40L%3D8005594%40B%3D1%40p%3D1718651605%40i%3DU%C3%97008001017%40&sot=ST&zot=ST&soei=8000105&zoei=8005594&hd=2024-08-22T05:46:11&hza=D&hz=%5B%5D&ar=false&s=true&d=false&vm=00,01,02,03,04,05,06,07,08,09&fm=false&bp=true';
export const trainFromBerlinCentralStation =
  'https://int.bahn.de/en/buchung/fahrplan/suche#sts=false&so=Berlin%20Hbf&zo=Soltau%20Nord&kl=2&r=13:16:KLASSENLOS:1&soid=A%3D1%40O%3DBerlin%20Hbf%40X%3D13369549%40Y%3D52525589%40U%3D80%40L%3D8011160%40B%3D1%40p%3D1718651605%40i%3DU%C3%97008065969%40&zoid=A%3D1%40O%3DSoltau%20Nord%40X%3D9861014%40Y%3D53001361%40U%3D80%40L%3D8005594%40B%3D1%40p%3D1718651605%40i%3DU%C3%97008001017%40&sot=ST&zot=ST&soei=8011160&zoei=8005594&hd=2024-08-22T05:46:11&hza=D&hz=%5B%5D&ar=false&s=true&d=false&vm=00,01,02,03,04,05,06,07,08,09&fm=false&bp=true';
export const hotelGoogleMaps =
  'https://www.google.de/maps/dir//Winsener+Straße+111,+29614+Soltau/@53.0017002,9.7903546,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x47b1b35f8a0e5ea9:0x581ef3c0be6300d0!2m2!1d9.85902!2d53.00172';
/* eslint-enable */
