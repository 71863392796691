import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { eventDate } from '../../utils/eventDate';
import { useConference } from '../common/ConferenceProvider';
import { Conference } from '../common/types';
import './Sponsoring.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default function Sponsoring() {
  const conf = useConference();
  const [conference, setConference] = useState<Conference | undefined>();
  useEffect(() => {
    if (conference === undefined) {
      conf.conference().then((con) => setConference(con));
    }
  }, [conf, conference]);

  const sponsoring =
    'mailto:sponsoring@socrates-conference.de?subject=I%20would%20like%20information%20about%20sponsoring';
  const year = conference ? `${conference.year}` : '';
  const conferenceDate = eventDate(conference?.startDate, conference?.endDate);
  return (
    <div className="container sponsoring-container">
    <div className="row">
        <div className="col-sm-12">
            <div className="page-header">
                <h1>Sponsoring SoCraTes</h1>
            </div>
        </div>
    </div>
    <div className="row">
        <div className="col">
            <p>Thank you for your interest in making SoCraTes {year} happen!</p>
            <p>
                We invite you to sponsor the conference and help make this a brilliant event happen. Your support is
                crucial for keeping the event affordable for attendees.
            </p>
        </div>
    </div>
    <div className="row">
        <div className="col card card-body bg-light">
            <h3>About the event</h3>
            <p>
                The next annual International Conference for Software Craft and
                Testing will take place from {conferenceDate} in Soltau, Germany.{' '}
            </p>
            <p>
                We have the entire hotel to ourselves - and up to 220 people can
                participate.
            </p>
            <p>
                The event will be non-commercial, community-driven, and low-price. A
                two-day Open Space, which may include talks, sessions, workshops and
                other formats. SoCraTes is an international non-profit conference
                for craftspeople, by craftspeople.
            </p>
            <p>
                Contact:{' '}
                <a href={sponsoring}>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span> sponsoring@socrates-conference.de</span>
                </a>
            </p>
        </div>
    </div>
    <div className="row">
        <div className="col">
            <h2>Our Offer</h2>
            <p>
                We have split our sponsorship into two categories: Guaranteed Seat Sponsoring and Marketing Sponsoring,
                allowing sponsors to choose the option that best fits their needs.
            </p>
        </div>
    </div>
    <div className="row">
        <div className="col">
            <h3>Guaranteed Seat Sponsoring</h3>
            <p>Tickets for the conference are usually distributed via an impartial, randomized lottery process. When
                someone applies for a ticket through this process, they cannot be sure they can go, until their name is
                drawn from the pool of all applicants.</p>
            <p>
                As a guaranteed seat sponsor, you will be awarded up to 5 guaranteed seats at 900
                &euro; each. This means, for example, that you can plan travel
                and/or the contents of an Open Space session for up to 5 of your
                employees, without having to worry about any of them not being able
                to join.
            </p>
            <p>
                For each seat, please still apply via the website. This way, you can
                choose which days to participate on. Please note that the usual
                ticket costs are not covered by the sponsoring and have to be paid
                on top.
            </p>
        </div>
    </div>
    <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
            <table className="sponsored-seats">
                <thead>
                    <tr>
                        <th>Contribution Amount</th>
                        <th>Guaranteed seats</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>900 &euro;</td>
                        <td>1 seat</td>
                    </tr>
                    <tr>
                        <td>1800 &euro;</td>
                        <td>2 seats</td>
                    </tr>
                    <tr>
                        <td>2700 &euro;</td>
                        <td>3 seats</td>
                    </tr>
                    <tr>
                        <td>3600 &euro;</td>
                        <td>4 seats</td>
                    </tr>
                    <tr>
                        <td>4500 &euro;</td>
                        <td>5 seats</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="col-md-3"></div>
    </div>
    <div className="row">
        <div className="col">
            <p>
                <em>Note: Sponsored seats are limited and distributed on a first-come-first-served basis.</em>
            </p>
        </div>
    </div>
    <div className="row">
        <div className="col">
            <h3>Marketing Sponsoring</h3>
            <p>As a marketing sponsor, you gain various benefits to increase your brand exposure in the software craft
                and testing community:</p>
        </div>
    </div>
    <div className="row">
        <div className="col">
            <table className="sponsored-seats">
                <thead>
                    <tr>
                        <th>Contribution Amount</th>
                        <th>Marketing Benefits</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>400 &euro;</td>
                        <td><strong>Online Marketing:</strong> Logo on our website + social media announcements</td>
                        <td>Available</td>
                    </tr>
                    <tr>
                        <td>600 &euro;</td>
                        <td>+ <strong>Merch</strong>: Permission to place merch at the venue</td>
                        <td>Available</td>
                    </tr>
                    <tr>
                        <td>1100 &euro;</td>
                        <td>+ <strong>Market Place Announcements:</strong> Announcement during the marketplaces during
                            the conference</td>
                        <td>Available</td>
                    </tr>
                    <tr>
                        <td>1400 &euro;</td>
                        <td>+ <strong>Poster Placement:</strong> Permission to bring a poster that we will hang at the
                            venue
                        </td>
                        <td>Available</td>
                    </tr>
                    <tr>
                        <td>2000 &euro;</td>
                        <td>+ <strong>Training Day / Retreat Stewardship:</strong> Permission to place a full size
                            banner on the day of the respective event and association (naming) with the event. This is
                            an exclusive offer for a single sponsor per day.
                        </td>
                        <td>Both Available</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div className="row">
        <div className="col">
            <p>
                <em>Note: Each packages contains all the benefits above that package, too. Marketing packages are limited and distributed on a first-come-first-served basis.</em>
            </p>
        </div>
    </div>
    <div className="row">
        <div className="col">
            <p>For questions and inquiries please send an email to <a
                    href="mailto:sponsoring@socrates-conference.de">sponsoring@socrates-conference.de</a>. We are
                looking forward hearing from you.</p>
        </div>
    </div>
</div>
  );
}
