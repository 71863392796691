import React from 'react';
import config from '../../../config';
type Props = {
  url: string;
  logo: string;
  name: string;
};
export default function SponsorComponent({ url, logo, name }: Props) {
  return (
    <div className="col-lg-6">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src={`${config.logoPath}/logos/${logo}`} alt={name} />
      </a>
    </div>
  );
}
