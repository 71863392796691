import newsletterSignOutReducer from '../newsletter-signout/NewsletterSignOutReducer';
import newsletterSignUpReducer from '../home/newsletter/newsletterSignUpReducer';
import newsletterConfirmReducer from '../newsletter-confirm/NewsletterConfirmReducer';
export type NewsletterState = {
  message: string;
};

const newsletterReducer = (state: NewsletterState, event: any) => {
  let nextState: NewsletterState = newsletterSignUpReducer(state, event);
  nextState = newsletterSignOutReducer(nextState, event);
  nextState = newsletterConfirmReducer(nextState, event);
  return nextState;
};

const reducerMap = {
  newsletter: newsletterReducer,
};
export default reducerMap;
