import { Link } from 'react-router-dom';
import React from 'react';
import brandLogo from '../../../assets/img/apple-touch-icon-152x152-precomposed.png';

interface Props {
  title: string;
  year: number;
}

export default function NavigationBrand({ title, year }: Props) {
  return (
    <Link className="navbar-brand" to="/home" title="Home">
      <span>
        <img src={brandLogo} alt="logo" width={40} height={40} />
      </span>
      <span>
        {' '}
        {title} {year}
      </span>
    </Link>
  );
}
