import React from 'react';
import PropTypes from 'prop-types';
type NicknameInputProps = {
  validationClass: string;
  value: string;
  onChange: (arg0: React.ChangeEvent<HTMLInputElement>) => void;
};
export function NicknameInput(props: NicknameInputProps) {
  const { validationClass, value, onChange } = props;
  return (
    <div className="form-group col">
      <label htmlFor="nickname" className="col-form-label">
        Nickname
      </label>
      <input
        type="text"
        name="nickname"
        className={`form-control ${validationClass}`}
        value={value}
        id="nickname"
        onChange={onChange}
        required
        placeholder="Your nickname"
        aria-describedby="nicknameHelp"
      />
      <small id="nicknameHelp" className="form-text text-muted">
        We need your nickname to communicate with you.
      </small>
    </div>
  );
}
NicknameInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  validationClass: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
