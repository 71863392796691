import React from 'react';
import PropTypes from 'prop-types';
type EmailInputProps = {
  id?: string;
  className?: string;
  helpText?: string;
  labelText?: string;
  onChange: (arg0: React.ChangeEvent<HTMLInputElement>) => void;
  placeholderText?: string;
  validationClass: string;
  value: string;
};
export function EmailInput(props: EmailInputProps) {
  const {
    id,
    className = 'form-group col',
    helpText = "We'll never share your email with anyone else, but we need it to contact you.",
    placeholderText = 'Your email',
    labelText = 'Email',
    validationClass,
    value,
    onChange,
  } = props;
  return (
    <div className={className}>
      <label htmlFor="email" className="col-form-label">
        {labelText}
      </label>
      <input
        type="email"
        name="email"
        className={`form-control ${validationClass}`}
        value={value}
        id={id ?? 'email'}
        onChange={onChange}
        required
        placeholder={placeholderText}
        aria-describedby="emailHelp"
      />
      <small
        id="emailHelp"
        className="form-text text-muted"
        dangerouslySetInnerHTML={{
          __html: helpText.replaceAll('\n', '<br />'),
        }}
      />
    </div>
  );
}
EmailInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  validationClass: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
