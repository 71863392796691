const newToPairs = <T>(acc: T[][], item: T, index: number): T[][] => {
  if (index % 2 !== 0) {
    acc[acc.length - 1].push(item);
  } else {
    acc.push([item]);
  }

  return acc;
};

const toArrayOfPairs = <T>(array: T[]): T[][] => {
  if (array) return array.reduce(newToPairs, [] as T[][]);
  else return [] as T[][];
};

export default toArrayOfPairs;
