import React, { createContext, PropsWithChildren, useContext } from 'react';
import { Applicant } from './types';
import { ApplicantsApi } from './api/applicants';

type ApplicantsContext = {
  addApplicant: (applicant: Applicant) => Promise<void>;
  withdraw: (email: string) => Promise<void>;
};

const DEFAULT_CONTEXT: ApplicantsContext = {
  addApplicant: async (applicant) => {
    console.log('not adding', applicant);
  },
  withdraw: async (email) => {
    console.log('not withdrawing', email);
  },
};
const context = createContext(DEFAULT_CONTEXT);
export const useApplicants = () => useContext(context);

type ProvideApplicantsProps = {
  applicantsApi: ApplicantsApi;
};

export const ApplicantsProvider = ({
  applicantsApi,
  children,
}: PropsWithChildren<ProvideApplicantsProps>) => {
  const applicants = {
    addApplicant: applicantsApi.addApplicant,
    withdraw: applicantsApi.withdraw,
  };
  return <context.Provider value={applicants}>{children}</context.Provider>;
};
