export default class NewsletterCommand {
  static get SIGN_OUT() {
    return 'Command/NEWSLETTER_SIGN_OUT';
  }

  static get SIGN_UP() {
    return 'Command/NEWSLETTER_SIGN_UP';
  }

  static get CONFIRM() {
    return 'Command/NEWSLETTER_CONFIRM';
  }
}
export const signOut = (email: string) => ({
  type: NewsletterCommand.SIGN_OUT,
  email,
});
export const signUp = (username: string, email: string) => ({
  type: NewsletterCommand.SIGN_UP,
  username,
  email,
});
export const confirm = (consentKey: string) => ({
  type: NewsletterCommand.CONFIRM,
  consentKey,
});
