import React from 'react';
export default function Separator() {
  return (
    <div className="row">
      <div className="col-lg-12 visible-lg-block">
        <hr />
      </div>
    </div>
  );
}
