import { Link } from 'react-router-dom';
import React from 'react';
const faqItems = [
  {
    title: "I'd like to attend with my family. Is that possible?",
    content: (
      <div>
        <p>
          Great question, thank you! We're very happy for participants to attend
          with their family.
        </p>
        <p>
          All adults attending need to sign up for the lottery. Once you are
          drawn in the lottery, we will ask for your room requirements. We have
          a number of connecting rooms which can be used by families with kids
          who sleep separately.
        </p>
        <p>
          We will also ask for your requirements regarding professional
          childcare after the lottery. We are prepared to accommodate parents
          who need childcare at the conference days, but we'll decide on the
          specifics based on the number of requests we get. Please reach out to
          us if this is a major concern to you.
          <p />
          The hotel has a play room for kids (to be used under supervision).
          There is also a garden and swimming pool available at the hotel. In
          addition the Heide Park can be found just 3km away.
        </p>
      </div>
    ),
  },
  {
    title: "How can I account the expenses when I'm self-employed?",
    content: <p>We will issue an invoice shortly after the conference.</p>,
  },
  {
    title: "When's the best time to arrive on Thursday?",
    content: (
      <div>
        <p>
          Conference starts at ~5pm. In order to enjoy a relaxed start into the
          conference, it&#39;s best to arrive early enough to check-in, leave
          your luggage in your room, get a cold drink and start having some
          interesting conversations all before 5pm.{' '}
        </p>
        <p>
          However, be aware that the evening&#39;s buffet will be served from
          6pm to 7.30pm and that the official World Cafe will kick off the
          conference at 7.30pm.{' '}
        </p>
      </div>
    ),
  },
  {
    title: 'What time does the conference end on Saturday?',
    content: (
      <p>
        The closing of Saturday&#39;s sessions will start at 4.30pm, and will be
        followed by a joint retrospective, which usually lasts ~1h. Without
        giving any guarantees, it&#39;s likely that the conference will be over
        at around 5-5.30pm.{' '}
      </p>
    ),
  },
  {
    title: 'What time do the workshops end on Sunday?',
    content: (
      <p>
        On Sunday, all workshops must end by 6pm. However, each workshop has the
        freedom to determine when it ends as long as the 6pm deadline is
        respected.
      </p>
    ),
  },
  {
    title: 'Is SoCraTes the best conference ever?',
    content: (
      <p>
        Funny you should ask. Yes, certainly, because you make it the best it
        can be for yourself.
      </p>
    ),
  },
  {
    title: 'How do I get in touch with the organisers?',
    content: (
      <div>
        <p>
          You can ask your questions by sending an e-mail to{' '}
          <a href="mailto://info@socrates-conference.de">
            info@socrates-conference.de
          </a>
          .{' '}
        </p>
        <p>
          Another option is to use our slack channel:{' '}
          <a href="https://socrates-conference.slack.com">
            https://socrates-conference.slack.com
          </a>
        </p>
        <p>
          If you didn&#39;t get invited yet, just email us:{' '}
          <a href="mailto:info@socrates-conference.de">
            info@socrates-conference.de
          </a>
        </p>
      </div>
    ),
  },
  {
    title:
      'What are the visa regulations for non-EU nationals who want to participate?',
    content: (
      <div>
        <p>
          Your can find information about visa regulations on the website of the
          &nbsp;
          <a
            href="https://www.auswaertiges-amt.de/en/visa-service/visabestimmungen-node"
            target="_blank"
            rel="noopener noreferrer"
          >
            Federal Foreign Office
          </a>
          .
        </p>
      </div>
    ),
  },
  {
    title: 'How to get assistance with visa issues?',
    content: (
      <div>
        <p>
          As a registered participant you can ask the organisers to write a visa
          invitation letter.
        </p>
        <p>
          For requesting a letter, write an e-mail to{' '}
          <a href="mailto:info@socrates-conference.de">
            info@socrates-conference.de
          </a>
          &nbsp;and submit the following information: your name and passport
          number, your country of residence, address, e-mail and phone number,
          the name of the Embassy, the address and the phone number of the
          Embassy.
        </p>
      </div>
    ),
  },
  {
    title: 'Where can I talk to other participants?',
    content: (
      <div>
        <p>
          Please use our slack channel:{' '}
          <a href="https://socrates-conference.slack.com">
            https://socrates-conference.slack.com
          </a>
        </p>
        <p>
          There you can talk to other participants about all sorts of topics.
          For example, to find a roommate or share a ride by train, car or taxi
          on your way to SoCraTes or back home.
        </p>
        <p>
          If you didn&#39;t get invited yet, just email us:{' '}
          <a href="mailto:info@socrates-conference.de">
            info@socrates-conference.de
          </a>
        </p>
      </div>
    ),
  },
  {
    title: 'How will I get to SoCraTes?',
    content: (
      <div>
        <p>
          SoCraTes takes place at &nbsp;
          <a
            href="http://www.hotel-park-soltau.de/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hotel Park Soltau (website only in german)
          </a>
          .
          <small>
            <br />
            <a
              href="https://www-hotel--park--soltau-de.translate.goog/?_x_tr_sl=de&_x_tr_tl=en&_x_tr_hl=de"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here for translated english version
            </a>
            .
          </small>
        </p>
        <p>
          <strong>Need some help finding the way </strong>? Check out the{' '}
          <Link to="/location">location page </Link> for the details about the
          conference&#39;s venue.
        </p>
      </div>
    ),
  },
  {
    title: 'What about COVID-19 measures?',
    content: (
      <div>
        <p>
          Although COVID-19 has now largely disappeared from the news, and
          statistics are no longer maintained properly, there are still a
          noticeable number of infections in Germany every day. We intend to
          keep attendees safe during the conference and have decided to keep in
          place our COVID measures:
        </p>
        <ul>
          <li>
            Attendees are kindly requested to take a provided lateral flow test
            upon arrival. You will not be allowed on the premise otherwise and
            will be charged a night's stay as per hotel policy.
          </li>
          <li>
            Additionally, attendees will receive one lateral flow test for each
            day. We trust you to inform the organisers in case you test
            positive.
          </li>
          <li>
            We recommend attendees bring and wear FFP2 masks indoors, but we
            will not enforce the wearing of masks.
          </li>
        </ul>
        <p>Thank you for your cooperation!</p>
      </div>
    ),
  },
  {
    title: 'Regulations when visiting from abroad?',
    content: (
      <div>
        <p>
          <a
            href="https://www.auswaertiges-amt.de/en/visa-service/buergerservice/faq/-/606848"
            target="_blank"
            rel="noopener noreferrer"
          >
            Please inform yourself about the current regulations before
            traveling to Germany
          </a>
        </p>
        <p>
          About COVID-19: As of 11 July 2022 all COVID-19 related restrictions
          regarding entry to Germany have been dropped.
        </p>
      </div>
    ),
  },
  {
    title:
      'Is financial support available for those who cannot afford the full cost of the conference?',
    content: (
      <div>
        <p>
          We aim to make the conference affordable for all, recognizing that
          financial constraints can be a barrier. While we keep costs low, we
          understand it can still be significant for some attendees. Therefore,
          we offer financial aid to help reduce these costs for those in need.
        </p>
        <p>
          If you require support, please indicate your need during profile
          completion once you secure a seat via lottery. This helps us gauge the
          need and allocate our budget to assist as many participants as
          possible. The option to request financial aid will be available in
          your profile after securing a seat.
        </p>
      </div>
    ),
  },
  {
    title: 'I would like to pring a pet, is that possible?',
    content: (
      <div>
        <p>
          It is possible to bring a dog or a cat and have them around you while
          attending SoCraTes at Hotel Park Soltau. However, the number of rooms
          that the Hotel Park can offer to accommodate a dog or a cat is
          limited, which is why we ask you to contact us in case you want to
          bring a pet, so that we can make sure that a suitable room is
          available for you and your pet. Please note that we (and also Hotel
          Park Soltau) have no previous experience with accommodating pets other
          than dogs or cats at the time of this writing and that such a request
          will have to be clarified with Hotel Park staff well in advance of
          your arrival.
        </p>
        <p>
          Please also note that the Hotel Park will ask for a surcharge on your
          booking of currently 30 Euros flat (not per night) for extra cleaning
          of your room. This is to make sure that all hairs are removed as other
          guests might be allergic to them. It is also expected that a cat
          toilet, in case you bring one, is adequately disposed off by you and
          not left behind to be disposed off by hotel staff.
        </p>
      </div>
    ),
  },
];
export default faqItems;
