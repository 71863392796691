export const abstracts = {
  experiencing_team_flow: {
    author: 'Michel Grootjans',
    title: 'Experiencing team flow',
    abstract: `<div>
        <p>You will experience the effects of team flow in this non-technical workshop.</p>
        <p>We will be running a few interactive simulations on team organisation and the effect this has on the total flow of work.</p>
        <p>Topics we will certainly address:</p>
        <ul>
          <li>Why would we prefer T-shaped team members?</li>
          <li>How can swarming help?</li>
          <li>Why would you deliberately limit work in progress?</li>
          <li>Why is it that we have trouble estimating when work will be done?</li>
        </ul>
        <p>Bring your mobile phone if you want to participate. You will only use your browser.</p>
      </div>`,
    bio: `<div>
        <p>Michel Grootjans has been programming since the age of 12. He has programmed strange machines like the TI 99-4A, the Atari 2600, Mac128, HP28, Apple II, Siemens PLC's, using languages like Basic, Pascal, C, HyperTalk, Assembler, ... along the way.</p>
        <p>His professional experiences include building enterprise applications for retail, media, government, chemical plants, telecom, HR, insurance, ... in javascript, C#, java and, ruby.</p>
        <p>He helps teams with continuous improvement, focusing on outcomes over output.</p>
      </div>`,
  },
  dissecting_communication: {
    author: 'Stefanie Hasler (fii)',
    title: 'Dissecting Communication',
    abstract: `<div>
        <p>As we learned from Woody last year, communication always fails on some level. Which is cool, because that means there’s room for improvement.</p>
        <p>In this workshop we’ll talk about Schulz v. Thun’s four sides-model for communication and how it can help us debug a situation that has gone wrong.</p>
        <p>If you’d like, please bring an example where you know a communication mistake has occurred, so we have something to work with.</p>
      </div>`,
    bio: '',
  },
  secure_development: {
    author: 'Lisi Hocke',
    title:
      'Secure Development Lifecycle Applied - How to Make Things a Bit More Secure than Yesterday Every Day',
    abstract: `<div>
        <p>Building valuable solutions is a complex endeavor that requires a breadth of knowledge. That not being enough, we’re also getting asked to build secure solutions in a secure way - yet what does that even mean? How do we incorporate such a vast area of expertise into our everyday workflows?</p>
        <p>In this hands-on workshop, I will introduce you to core security concepts, like the CIA triad or defense in depth - and how we can apply them in everyday work. Based on a practical example, we will go through the development lifecycle with security in mind. You will learn about threat modeling to uncover risks early on, secure coding principles to bake security in, security testing approaches to make informed decisions depending on your risk appetite, and ways of detecting potentially malicious activity to protect against. Interactive exercises at each step will let you experience how security can neatly fit with what you’re already doing without adding artificial gates.</p>
        <p>Whether you want to keep your system secure or get a neglected one back in shape, this session is for you. Join us to gain fundamental security knowledge, hone your security skills, and get tactical advice to secure your development lifecycle. Let’s make things a bit more secure than yesterday every day!</p>
      </div>`,
    bio: `<div>
        <p>Lisi found tech as her place to be in 2009 and has grown as a specialized generalist ever since. She's passionate about the whole-team approach to holistic testing and quality and enjoys experimenting and learning continuously. Building great products that deliver value together with great people motivates her and lets her thrive. Having received a lot from communities, she's paying it forward by sharing her stories and learning in public. She posts on Mastodon as @lisihocke@mastodon.social and blogs at www.lisihocke.com. In her free time, she plays indoor volleyball or delves into computer games and stories of all kinds.</p>
      </div>`,
  },
  learning_hours: {
    author: `<a href="https://www.linkedin.com/in/emilybache/" title="Follow Emily Bache on LinkedIn" target="_blank" rel="noreferrer">Emily Bache</a>`,
    title: 'Learning Hours - Hands-on Team Building for Developers',
    abstract: `<div>
        <p>As a tech lead or senior developer, you’re expected to mentor and train more junior team members - coaching is already part of your job. In this training with Emily Bache, you’ll learn skills and concrete activities you can use to bring on others and build up your team. This course will help you to get started with “learning hour” sessions. These are not vacuous “icebreaker” style fun and games, the focus is on real hard technical skills, especially around TDD, refactoring, handling legacy code and better OO design. We’ll talk about structuring a session with “Training from the Back of the Room” and how Learning Goals can help you to plan and deliver relevant content. Real technical coaching is about leading team activities that strengthen your collaboration and ability to discuss code and make better design decisions. You don’t need to be a full-time technical coach to make a difference in your organization.</p>
        <p>This session is open for everyone but particularly aimed at tech leads and senior developers. Bring a laptop in order to access the online Miro board we will use in the session.</p>
      </div>`,
    bio: `<div>
        <p>Emily Bache is an independent consultant, YouTuber and Technical Coach. She works with developers, training and coaching effective agile practices like Refactoring and Test-Driven Development. Emily has worked with software development for 25 years, written two books and teaches courses on platforms including Pluralsight and O'Reilly. A frequent conference speaker, Emily has been invited to keynote at prestigious developer events including EuroPython, Craft and ACCU. Emily founded the Samman Technical Coaching Society in order to promote technical excellence and support coaches everywhere.</p>
      </div>`,
  },
  code_reading_workshop: {
    author: `Marit van Dijk`,
    title: 'Code Reading (workshop)',
    abstract: `<div>
        <p>As developers, we spend a lot of time learning to write code, while spending little to no time learning to read code. Meanwhile, we often spend more time reading code than actually writing it. Shouldn’t we be spending at least the same amount of time and effort improving this skill? Deliberate practice can help us get better at reading code. Learning how to better read and understand code, can in turn teach us what makes code readable. This might even help us to write code that is easier to read.</p>
        <p>In this workshop we will practice our code reading skills by reading an unfamiliar piece of code, using structured exercises. Participants will practice reading code, and take away knowledge about how they can continue to improve this important skill.</p>
      </div>`,
    bio: `<div>
        <p>Marit van Dijk is a software developer with 20 years of experience in different roles and companies. She is a Java Champion and works as a Developer Advocate at JetBrains. She loves building awesome software with amazing people and making developers lives better.</p>
        <p>She enjoys learning new things as well as sharing knowledge on programming, software development, testing & test automation, and more. She has contributed to open-source projects like Cucumber and several other projects.</p>
        <p>Marit speaks at international conferences, in webinars, and on podcasts, occasionally writes blog posts, and contributed to the book “97 Things Every Java Programmer Should Know” (O’Reilly Media).</p>
      </div>`,
  },
  untangle_test_code: {
    author: 'Michael Kutz',
    title: 'How to untangle your Spaghetti test code',
    abstract: `<div>
        <p>In many teams we worked in, test code was treated much less carefully than production code. It was expected to just work. Mindless copy and paste of setup code from one test case to another was never seen problematic, duplications widely accepted, and things were named randomly.</p>
        <p>This always leads to problems: gaps in assertions become pretty non-obvious; consolidating long running test suites becomes a cumbersome task; magic numbers need to be changed all across the suite, when they become outdated. All of this affects the overall maintainability of our code base.</p>
        <p>Over the years we identified several good practices to prevent these problems and keep test code maintainable. Some borrowed from general good code quality standards, some specific for test code.</p>
        <p>In this workshop, we are going to briefly discuss the properties of good test code. Then we’ll present our good practices and let you apply these to a prepared test suite. Lastly you will discuss action items in your day job.</p>
      </div>`,
    bio: `<div>
            <p>I have been working in professional software development for more than 12 years. I love writing working software and I hate having to fix bugs. Therefore, I developed a strong focus on test automation, continuous delivery/deployment and agile principles.</p>
            <p>Later I came to the realisation that the most sustainable way to fix code is to optimise those who code. For this reason, I have worked more intensively on psychological safety, cognitive biases and ways to spread knowledge in software-producing organisations.</p>
            <p>Since 2014, I work at REWE digital as a software engineer and internal coach for quality assurance and testing. My main goal is to support our development teams in the areas of quality assurance and test automation so that they are able to write great, bug-free software quickly.</p>
        </div>`,
  },
  ensemble_programming: {
    author: 'Simon Harrer',
    title: 'Ensemble Programming',
    abstract: `<div>
        <p>Let’s experience ensemble programming and its consequences. We re-live a typical day of a team that uses “extreme" ensemble programming: all the time for every activity with the whole team. We’ll learn what this would entail, and how it would change how we work.</p>
      </div>`,
    bio: `<div>
        <p>Dr. Simon Harrer is a curious tech lead at INNOQ who loves to work together and share his knowledge. As a serial co-author, Simon is known for only publishing articles, books, or websites together with others, except for his PhD thesis he had to suffer through alone. In his last project, he discovered his favorite way of working, remote ensemble programming, for which he obviously co-authored a website and short book, using remote ensemble programming in doing so. Nowadays, apart from his typical consulting projects, he coaches remote teams to become better by not only working together but also growing together.</p>
      </div>`,
  },
  organizational_design: {
    author: 'Bas Vodde',
    title:
      'Organizational design principles for scaling and a summary of their impact',
    abstract: `<div>
        <p>Adaptive organizations are optimized to maximize value delivery and the ability to respond to changes in its environment, as opposed to efficiency and predictability. This has been the goal of the agile development movement. Unfortunately agile often ends up in organizations as a shallow process change without having a big impact. Agile processes do have it’s place but in order to achieve adaptiveness organizations have to create simpler organizational structures.</p>
        <p>This session will go over the seven organizational design principles to create simpler organization. Some examples will be shared for each principle usually taken from the LeSS organizational design system. The outcome of this session is an insight in the reasoning behind LeSS or an inspiration to make your organization simpler and more adaptive.</p>
      </div>`,
    bio: `<div>
        <p>Bas Vodde is a coach, programmer, trainer, and author related to modern agile and lean product development. He is the creator of the LeSS (Large-Scale Scrum) framework for scaling agile development. He coaches organizations on three levels: organizational,  team,  individual/technical practices. He has trained thousands of people in software development, Scrum, and modern agile practices for over a decade.</p>
        <p>Bas works for Odd-e, a company that supports organizations in improving their product development, mostly in Asia and Europe.</p>
      </div>`,
  },
  practical_pm_wo_estimates: {
    author: 'Daniel Bartholomae',
    title: 'Practical Project Management without Estimates',
    abstract: `<div>
        <p>We will explore the practical aspects of forgoing estimates on the example of Recipe.ai, a (made-up) startup in the recipe-generation space that tries to move from waterfall planning and estimates to no-estimates. After a short theoretical overview, we will jump into practical exercises where we will jump into the roles of developers, product managers, and their stakeholders to explore typical problems we can run into with no-estimates as well as solutions for them.</p>
      </div>`,
    bio: `<div>
        <p>Daniel is a tech entrepreneur and startup CTO, focusing on the practical aspects of software craft. They have organised conferences and meetup events in the space of software craft during the last years and brought software craft to life in startups and scale-ups.</p>
      </div>`,
  },
  intro_systems_thinking: {
    author: `Diana Montalion & <a href="https://andrewharmellaw.github.io/" target="_blank" title="Andrew's github.io page" rel="noreferrer">Andrew Harmel-Law</a>`,
    title: 'Intro to Systems Thinking',
    abstract: `<div>
        <p>Systems thinking expands your capacity to do difficult things. For software developers, relational complexity is increasingly difficult. Cross-functional thinking is difficult to orchestrate. Changing entrenched organizational patterns is difficult. Thinking in systems … is difficult.</p>
        <p>Peter Senge has demonstrated that we blame the wrong things (events, situations or processes) for our systemic problems. W. Edwards Deming says that 94% of the time, the system is to blame for performance issues, not the individual parts of the system. Jay Forrester discovered counterintuitiveness: most organizations “fix” systemic problems by inadvertently making them worse.</p>
        <p>In this workshop, you’ll learn why systems thinking is difficult to master. What are the blockers and challenges?</p>
        <p>You’ll learn a few core practices that will expand your skillset. We’ll use the Iceberg Model to explore the root cause of recurring systems problems. You can use these practices to help you develop impactful recommendations.</p>
      </div>`,
    bio: `<div>
        <h5>Diana Montalion</h5>
        <p>Diana is the author of the O’Reilly book, Learning Systems Thinking: Essential Nonlinear Skills & Practices for Software Professionals. She has twenty years experience engineering and architecting software systems for organizations including Stanford, The Gates Foundation, Memorial Sloan Kettering and Teach For All. She has served as Principal Systems Architect for The Economist and The Wikimedia Foundation. Her company, Mentrix, publishes learning materials for aspiring nonlinear thinkers and builds modern software systems for diverse clients.</p>
        <p>Diana lives in the Hudson Valley (New York, USA) with three dogs, one cat and nine chickens.</p>
      </div>
      <div>
      <h5>Andrew Harmel-Law</h5>
        <p>Andrew is a Tech Principal at Thoughtworks, and the author of Facilitating Software Architecture. He specializes in Java / JVM technologies, agile delivery, build tools/automation, and domain-driven design. Experienced across the software development lifecycle and in many sectors, he understands that people, architecture, process and tooling all have key roles to play in achieving this. He enjoys sharing his experience as much as possible. This sharing is not only seen in his formal consulting engagements, books and training for O’Reilly, but also informally through mentoring, blog posts, conferences (speaking and organizing), and open-sourcing his code.</p>
      </div>`,
  },
  trunk_based_development: {
    author: 'Thierry de Pauw',
    title: 'Trunk-based development for regulated environments',
    abstract: `<div>
        <p>There is an economic perspective to software engineering. The more frequently we deliver, the more we can run experiments, the more we can find new ways to delight our customers and the fewer risks we run.</p>
        <p>Trunk-based development together with the deployment pipeline satisfies this requirement. Trunk-based development allows us to grow a feature commit by commit. The deployment pipeline enables us to continuously deliver those commits into the hands of the users in production.</p>
        <p>In this workshop, we will design a deployment pipeline using Emily Bache’s award-winning pipeline card game in a trunk-based development setting for a fictive fintech that needs to comply with certain regulations. How can we satisfy regulations while committing straight to main multiple times per day?</p>
      </div>`,
    bio: `<div>
        <p>Thierry is a lean IT Engineer at the fintech startup Abbove. On the side, he founded ThinkingLabs, an advisory firm for optimising IT delivery.</p>
        <p>From time to time he is asked to conduct technology due diligence for investors to review the technology capabilities of organisations.</p>
        <p>Thierry is a CI/CD advocate and jack-of-all-trades. Instead of balancing quality & delivery, he believes and practices that better quality is actually a way to more and better deliveries.</p>
      </div>`,
  },
  expressing_gratitude: {
    author: 'Aki Salmi & Sofia Katsaouni',
    title: 'Expressing gratitude',
    abstract: `<div>
        <p>Viktor E. Frankl says:</p>
        <p>Between stimulus and response there is a space. In that space is our power to choose our response. In our response lies our growth and our freedom.</p>
        <p>In this Empathy @ Work workshop on Expressing Gratitude, we explore how we can regain awareness of being grateful and how to choose to express that to the other in a way that is easier for the other to hear and let it sink in.</p>
        <p>The consciousness of the choice happens in the space, and in this workshop, we explore how we can learn to make a conscious decision on how to express our gratitude toward the other in a way that strengthens the connection.</p>
        <p>The core learning of Empathy @ Work we want to convey is to be inspired to speak the most truth we have access to with the most care we have capacity for. Because that does take individuals, teams and organisations forward. In this session, we focus on speaking truth (which can be a vulnerable choice) while expressing gratitude.</p>
      </div>`,
    bio: `<div>
        <h5>Sofia</h5>
        <p>Sofia Katsaouni works as an Agile & Organisational Coach.</p>
        <p>Sofia is a coach who cares about people. She always places people and their needs at the center of her attention; when needs are met, people can form effective teams that deliver high customer value.</p>
        <p>Since Sofia is passionate about people systems rather than electrical systems, she transitioned from an electrical engineer to an Agile coach full time early in her career. Her electrical engineering background has helped her develop analytical, problem-solving, and empirical thinking skills.</p>
        <p>Sofia recognizes that trusting relationships and psychological safety are the basis of human-centric and performance-oriented cultures. Throughout the past few years, she has worked closely with individuals, engineering teams, and leadership to enable startups and scale-ups to create such environments in their organizations.</p>
        <p>When discovering the world of NVC Sofia started learning more and more about empathy and empathic listening, and practicing it in private life as well as in coaching sessions.</p>
        <p>Soon she realised the tremendous impact that it had on her and the people around her and thus wanted to spread the word of empathy further. In this capacity, Sofia began delivering Empathy@Work sessions at various companies and conferences throughout Europe</p>
        <h5>Aki</h5>
        <p>Aki does not believe in magic. He makes it happen. Being a programmer with very solid technical skills in testing, design and making code fun to work with, he gets stuff done. Someone once said ‘reading his code is like reading a good book.’</p>
        <p>In addition to the techy side, he is also well known for the empathetic presence he has - the listening skills are well known and the Empathy@Work workshops he holds are highly valued by the participants.</p>
        <p>This unique mix of solid technical skills and emotional IQ, he has lot to share to Software Crafters in the world. And he does that a lot in many unconferences, like the one he runs - Codefreeze.</p>
      </div>`,
  },
  functional_domain_modelling: {
    author: `<a href="https://linkedin.com/in/marco-emrich-47485388" title="Follow Marco Emrich on LinkedIn" target="_blank" rel="noreferrer">Marco Emrich</a>`,
    title: 'Workshop: From Vision to Code:  Functional Domain Modelling',
    abstract: `<div>
        <p>Modern collaborative methods such as event storming bring domain experts and developers together. "Domain modeling" goes one step further and enables collaborative work on code. In this workshop you can try it out "hands-on".</p>
        <p>Our PO will explain the domain and we claim to have the result of an event storming. We then model the domain using the type system using TypeScript (TS is not the focus, you don't need specific TS knowledge).</p>
        <p>This enforces invariants at compile time and experts can give direct feedback. The goal of the workshop is not only to use the type system for modeling, but also to practice communicating with POs, customers and other subject matter experts!</p>
      </div>`,
    bio: `<div>
        <p>Marco is an architect and consultant at codecentric, and a passionate advocate for software craft & quality, which he also teaches at the university of applied sciences in Salzburg. He regularly speaks at software engineering conferences and is the author of several technical books. When he's not buried deep in code, his children beat him in analog board games.</p>
      </div>`,
  },
  to_be_announced: {
    author: 'TBA',
    title: 'TBA',
    abstract: 'TBA',
    bio: '',
  },
} as const;

export type AbstractKey = keyof typeof abstracts;
