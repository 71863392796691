import ExternalLink from '../../common/ExternalLink';
import React, { ChangeEventHandler } from 'react';

type DataPrivacyInputProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  validationClass: string;
  checked: boolean;
};
export function DataPrivacyInput({
  checked,
  onChange,
  validationClass,
}: DataPrivacyInputProps) {
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <div className="input-group-text">
          <input
            id="application-dataPrivacy"
            type="checkbox"
            aria-labelledby="application-privacy-label"
            value="privacyStatementAcknowledged"
            checked={checked}
            onChange={onChange}
          />
        </div>
      </div>
      <label
        id="application-privacy-label"
        className={validationClass}
        style={{
          height: '100%',
        }}
      >
        <small>
          <div>
            I agree that my details from the application form will be collected
            and processed to determine the conference participants. The data
            will remain stored until the current year conference is over.
          </div>
          <div>
            Note: You can revoke your consent at any time for the future via
            e-mail to registration AT socrates MINUS conference DOT de, thereby
            we will delete your application / registration.
          </div>
          <div>
            Detailed information on handling user data can be found in our{' '}
            <ExternalLink
              url={`/privacy-policy`}
              target="_blank"
              title="privacy policy"
            >
              {' '}
              data privacy policy
            </ExternalLink>
          </div>
        </small>
      </label>
    </div>
  );
}
