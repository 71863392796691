class Exception extends Error {
  public type?: string;
}
export class InternalErrorException extends Exception {
  constructor() {
    super();
    this.type = 'InternalErrorException';
  }
}

export class BadRequestException extends Exception {
  constructor() {
    super();
    this.type = 'BadRequestException';
  }
}

export class NotFoundException extends Exception {
  constructor() {
    super();
    this.type = 'NotFoundException';
  }
}

export class AlreadyRegisteredException extends Exception {
  constructor() {
    super();
    this.type = 'AlreadyRegisteredException';
  }
}
