import React from 'react';
import './faq.scss';
import { useHistory } from 'react-router-dom';

export type Props = {
  title: string;
  index: number;
  content: React.ReactNode;
  id?: string;
  open?: boolean;
};
export default function FAQItem({ index, content, title, id, open }: Props) {
  const faqId = id || 'faq-item' + index;

  const [isOpen, setIsOpen] = React.useState(open);

  const history = useHistory();

  history.listen((location, action) => {
    const currentHash = location.hash;
    if (currentHash === '#' + faqId) {
      setIsOpen(true);
    }
  });

  return (
    <details id={faqId} open={isOpen}>
      <summary className="summary-class">
        {index + 1}. {title}
      </summary>
      <div className="content-class">{content}</div>
    </details>
  );
}
