import React from 'react';
import { Link } from 'react-router-dom';

import { faLinkedin, faMastodon } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faInfo,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import './footer.scss';

export default function Footer() {
  const mastodonUrl = 'https://mastodon.social/@SoCraTes_Conf';
  const linkedinUrl = 'https://www.linkedin.com/company/socrates-conference/';
  const mailToUrl = 'mailto:info@socrates-conference.de';
  return (
    <div id="footer" className="container">
      <footer>
        <div className="row">
          <div className="col-sm-12">
            <div className="btn-group float-end">
              <Link
                className="btn btn-info me-2 rounded"
                to="/imprint"
                title="Impressum"
              >
                <FontAwesomeIcon icon={faInfo} />
                <span className="d-none d-xl-inline"> Legal Notice</span>
              </Link>
              <Link
                className="btn btn-info me-2 rounded"
                to="/privacy-policy"
                title="Privacy Policy"
              >
                <FontAwesomeIcon icon={faUserSecret} />
                <span className="d-none d-xl-inline"> Privacy Policy</span>
              </Link>
              <a
                className="btn btn-info me-2 rounded"
                href={mailToUrl}
                title="E-Mail"
              >
                <FontAwesomeIcon icon={faEnvelope} />
                <span className="d-none d-xl-inline"> E-mail</span>
              </a>
              <a
                className="btn btn-info me-2 rounded"
                href={mastodonUrl}
                target="_blank"
                title="Mastodon"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faMastodon} />
                <span className="d-none d-xl-inline"> Mastodon</span>
              </a>
              <a
                className="btn btn-info me-2 rounded"
                href={linkedinUrl}
                target="_blank"
                title="LinkedIn"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
                <span className="d-none d-xl-inline"> LinkedIn</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
