import { FlatFee, RoomType } from './types';
import { ensure } from '../../utils/ensure';
import { differenceInDays } from 'date-fns';

export interface TicketOrder {
  flatFeeItems: FlatFee[];
  roomType: RoomType;
  arrival: string;
  departure: string;
}

export interface Position {
  label: string;
  price: Price;
}

interface Ticket {
  packages: Position[];
  accommodation: Position;
  total: Price;
  totalWithSponsoring: Price;
}

type Price = string;

const parsePrice = (price: Price): number => {
  return parseFloat(price.replace(',', '.'));
};

const formatEuro = (amount: number): Price => {
  let total: string = amount.toFixed(2);
  //if (total.endsWith('.00')) total = total.substring(0, total.length - 3)
  total = total.replace('.', ',');
  return total + '€';
};

export const calculateSponsorableAmount = (order: TicketOrder) =>
  formatEuro(
    ensure(
      order.flatFeeItems.reduce(
        (total, f) =>
          total + (!f.excludeFromSponsoring ? parsePrice(f.price) : 0),
        0,
      ),
    ),
  );

const calculateNights = (arrival: string, departure: string): number =>
  differenceInDays(new Date(departure), new Date(arrival));

function applySponsoring(items: number, sponsored: number): number {
  return sponsored < items ? items - sponsored : 0;
}

export const calculateTicketPrice = (
  order: TicketOrder,
  sponsoring?: Price,
): Price => {
  const nights = calculateNights(order.arrival, order.departure);
  const accommodation = nights * parsePrice(order.roomType.pricePerNight);
  const items = order.flatFeeItems.reduce(
    (total, item) => total + parsePrice(item.price),
    0,
  );
  const amount: number =
    accommodation +
    (sponsoring ? applySponsoring(items, parsePrice(sponsoring)) : items);
  return formatEuro(amount);
};

const formatPlural = (term: string, amount: number) =>
  `${amount} ${amount === 1 ? term : term + 's'}`;
const parens = (str: string) => `(${str})`;

const toPosition = (item: RoomType | FlatFee, nights?: number): Position => {
  if (nights !== undefined) {
    return {
      label: `${item.description} ${parens(formatPlural('night', nights))}`,
      price: formatEuro(parsePrice(item.pricePerNight) * nights),
    };
  } else {
    return {
      label: item.description,
      price: formatEuro(parsePrice(item.price)),
    };
  }
};

export const calculateTicket = (
  order: TicketOrder,
  sponsoring: Price = '0€',
): Ticket => {
  const packages = order.flatFeeItems.map((i) => toPosition(i));
  const accommodation = toPosition(
    order.roomType,
    calculateNights(order.arrival, order.departure),
  );
  const total: string = calculateTicketPrice(order);
  const totalWithSponsoring: string = calculateTicketPrice(order, sponsoring);
  return {
    packages,
    accommodation,
    total,
    totalWithSponsoring,
  };
};
