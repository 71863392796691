import React, { useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import NavigationBrand from './NavigationBrand'; // Assuming this is a custom component

import { withRouter } from 'react-router';
import './Navigation.scss';
import NavigationLink from './NavigationLink';
import NavigationApplicationLink from './NavigationApplicationLink';
import { useConference } from '../ConferenceProvider';
import { Conference, ConferenceState } from '../types';
import { ensure } from '../../../utils/ensure';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarCheck,
  faGraduationCap,
  faGlobe,
  faGift,
  faQuestionCircle,
  faHistory,
} from '@fortawesome/free-solid-svg-icons';
import { faAccessibleIcon } from '@fortawesome/free-brands-svg-icons';

// noinspection JSUnusedLocalSymbols
function Navigation() {
  const conf = useConference();
  const [conference, setConference] = useState<Conference | undefined>();
  const [applicationVisible, setApplicationVisible] = useState(false);
  useEffect(() => {
    if (conference === undefined) {
      conf.conference().then((con) => setConference(con));
    } else {
      setApplicationVisible(conference?.state === ConferenceState.REGISTRATION);
    }
  }, [conf, conference]);

  const collapsiblePanelId = 'navbarSupportedContent';
  return (
    <div id="navigation">
      {conference && (
        <Navbar
          bg="dark"
          variant="dark"
          expand="md"
          collapseOnSelect
          className="p-4"
        >
          <NavigationBrand
            title={conference?.title}
            year={ensure(conference?.year)}
          />
          <Navbar.Toggle aria-controls={collapsiblePanelId} />
          <Navbar.Collapse id={collapsiblePanelId}>
            <Nav className="mr-auto">
              <NavigationLink
                url="/format"
                title="Conference"
                icon={<FontAwesomeIcon icon={faCalendarCheck} />}
              />
              <NavigationLink
                url="/training"
                title="Training Day"
                icon={<FontAwesomeIcon icon={faGraduationCap} />}
              />
              <NavigationLink
                url="/location"
                title="Location"
                icon={<FontAwesomeIcon icon={faGlobe} />}
              />
              <NavigationLink
                url="/values"
                title="Values"
                icon={<FontAwesomeIcon icon={faGift} />}
              />
              <NavigationLink
                url="/accessibility"
                title="Accessibility"
                icon={<FontAwesomeIcon icon={faAccessibleIcon} />}
              />
              <NavigationLink
                url="/faq"
                title="FAQ"
                icon={<FontAwesomeIcon icon={faQuestionCircle} />}
              />
              <NavigationLink
                url="/history"
                title="History"
                icon={<FontAwesomeIcon icon={faHistory} />}
              />
              {applicationVisible && (
                <NavigationApplicationLink
                  title={`${conference.title} ${conference.year} Tickets`}
                  visible={applicationVisible}
                />
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}
    </div>
  );
}

export default withRouter(Navigation);
