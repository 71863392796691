import React from 'react';
import toArrayOfPairs from '../../../utils/toArrayOfPairs';
import SponsorRow from './SponsorRow';
import type { Sponsor } from './sponsoring.types';
export type Props = {
  title: string;
  sponsors: Sponsor[];
  showEmpty?: boolean;
};
export default function Sponsors({ title, sponsors, showEmpty }: Props) {
  const pairs = toArrayOfPairs(sponsors);
  const shouldRender = showEmpty || (pairs && pairs.length > 0);
  return shouldRender ? (
    <div>
      <div className="page-header">
        <h2>{title}</h2>
      </div>
      {pairs && pairs.length > 0 ? (
        pairs.map((pair: Sponsor[], index: number) => (
          <SponsorRow key={index} sponsors={pair} />
        ))
      ) : (
        <div className="row justify-content-center text-center">
          <h4 className="sponsor-empty">...coming soon...</h4>
        </div>
      )}
    </div>
  ) : (
    <div />
  );
}
