import React, { MouseEventHandler } from 'react';
import './newsletter.scss';
import ExternalLink from '../../common/ExternalLink';

export type Props = {
  name: string;
  email: string;
  hasValidName: boolean;
  hasValidEmail: boolean;
  message: string;
  onNameChange: (value: string) => void;
  onEmailChange: (value: string) => void;
  onSubmit: () => void;
  isDisabled: boolean;
  isDataPrivacyConfirmedChecked: boolean;
  dataPrivacyConfirmedChange: () => void;
};
export default function NewsletterSignUpForm(props: Props) {
  const klass = (isValid: boolean) =>
    `form-control mb-2 mr-sm-2 ${isValid ? 'is-valid' : 'is-invalid'}`;

  const nameClass = klass(props.hasValidName);
  const emailClass = klass(props.hasValidEmail);

  const checkklass = (isValid: boolean) =>
    `form-control ${isValid ? 'is-valid' : 'is-invalid'}`;

  const dataPrivacyConfirmedClass = checkklass(
    props.isDataPrivacyConfirmedChecked,
  );

  const onNameChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    props.onNameChange(event.currentTarget.value);
  };

  const onEmailChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    props.onEmailChange(event.currentTarget.value);
  };

  const onSubmit: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    props.onSubmit();
  };

  const renderMessage = () => {
    if (props.message && props.message !== '') {
      return (
        <div id="newsletter-message" className="pulse">
          {props.message}
        </div>
      );
    }

    return '';
  };

  return (
    <div>
      <div className="segment-header">
        <h3>Newsletter</h3>
      </div>
      <div>
        <p>
          From time to time we send out important information about SoCraTes. If
          you want to stay informed, let us know by using the form below.
        </p>
      </div>
      <form className="form">
        <label htmlFor="name" className="sr-only">
          Name
        </label>
        <input
          id="newsletter-name"
          name="name"
          type="text"
          className={nameClass}
          placeholder="Your nickname"
          required
          onChange={onNameChange}
          value={props.name}
        />
        <label htmlFor="email" className="sr-only">
          Email
        </label>
        <input
          id="newsletter-email"
          name="email"
          type="email"
          className={emailClass}
          placeholder="Your email"
          required
          onChange={onEmailChange}
          value={props.email}
        />
        <div className="mb-2">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <input
                  type="checkbox"
                  id="newsletter-dataPrivacy"
                  aria-label="Checkbox for following text input"
                  value="dataPrivacyConfirmed"
                  checked={props.isDataPrivacyConfirmedChecked}
                  onChange={props.dataPrivacyConfirmedChange}
                />
              </div>
            </div>
            <label className={dataPrivacyConfirmedClass}>
              <small>
                I agree that the data provided (nickname and e-mail) will be
                collected and processed for the purpose of confirming the
                newsletter subscription.
                <br />
                <br />
                Hint: You will receive an E-Mail to confirm your newsletter sign
                up. If you do not confirm within the next seven days, the
                newsletter will not be sent to you, and the data for your
                newsletter subscription will be deleted. You can also revoke the
                consent at any time for the future and unsubscribe from the
                newsletter by using the link in our newsletter or by visiting
                the&nbsp;
                <ExternalLink
                  url={`/newsletter-sign-out`}
                  target="_blank"
                  title="Newsletter sign out"
                >
                  sign out
                </ExternalLink>{' '}
                page.
                <br />
                <br />
                Detailed information on handling user data can be found in our{' '}
                <ExternalLink
                  url={`/privacy-policy`}
                  target="_blank"
                  title="privacy policy"
                >
                  {' '}
                  data privacy policy.
                </ExternalLink>
                <br />
              </small>
            </label>
          </div>
        </div>
        <button
          id="newsletter-form-button"
          className="btn btn-primary mb-2"
          disabled={props.isDisabled}
          onClick={onSubmit}
        >
          I am interested
        </button>
      </form>
      {renderMessage()}
    </div>
  );
}
