import React, { ChangeEventHandler, Component, FormEventHandler } from 'react';
import { connect } from 'react-redux';
import isValidEmailFormat from '../../utils/isValidEmailFormat';
import { EMPTY } from './NewsletterSignOutConstants';
import NewsletterSignOutForm from './NewsletterSignOutForm';
import './newsletterSignOut.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { signOut } from '../commands/newsletterCommand';
export type Props = RouteComponentProps & {
  match: {
    params: {
      data: string;
    };
  };
  message?: string;
  signOut?: (email: string) => void;
};
export type State = {
  email: string;
  hasValidEmail: boolean;
  isSubmitting: boolean;
  message: string;
};
const initialState = {
  email: EMPTY,
  hasValidEmail: false,
  isSubmitting: false,
  message: EMPTY,
};
export class NewsletterSignOutContainer extends Component<Props, State> {
  state = initialState;

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.message) {
      return { ...prevState, ...nextProps, isSubmitting: false };
    } else if (hasParams(nextProps)) {
      const email = nextProps.match.params.data;
      return {
        ...prevState,
        email,
        hasValidEmail: isValidEmail(email),
        isSubmitting: false,
      };
    } else {
      return prevState;
    }
  }

  onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    this.setState({ ...this.state, isSubmitting: true, message: EMPTY }, () => {
      this.props.signOut && this.props.signOut(this.state.email);
    });
  };
  onEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    event.persist();
    const email: string = event.target.value;
    this.setState({ ...this.state, email, hasValidEmail: isValidEmail(email) });
  };

  render() {
    const { email, hasValidEmail, isSubmitting, message } = this.state;
    const isDisabled = !hasValidEmail || isSubmitting;
    return (
      <div className="container">
        <div className="row">
          <div className="newsletter-sign-out">
            <NewsletterSignOutForm
              email={email}
              hasValidEmail={hasValidEmail}
              message={message}
              onEmailChange={this.onEmailChange}
              onSubmit={this.onSubmit}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

const hasParams = (nextProps: Props) =>
  nextProps.match &&
  nextProps.match.params &&
  Boolean(nextProps.match.params.data);

const isValidEmail = (email: string) =>
  email.length !== 0 && isValidEmailFormat(email);

const mapStateToProps = (state: any) => ({ ...state.newsletter });

const mapDispatchToProps = {
  signOut,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewsletterSignOutContainer),
);
