import { RouteComponentProps, withRouter } from 'react-router';
import React, {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from 'react';
import { isValidEmail } from '../../../utils/validation';
import LotterySignOutForm from './LotterySignOutForm';
import { useApplicants } from '../../common/ApplicantsProvider';
import { MESSAGES, MessageType } from '../form/applicationMessages';

type Props = RouteComponentProps & {
  match: {
    params: {
      data: string;
    };
  };
  message?: string;
  signOut?: (email: string) => void;
};

const LotterySignOut = (props: Props) => {
  const applicant = useApplicants();
  const [email, setEmail] = useState(props.match.params.data);
  const [hasValidEmail, setValidEmail] = useState(isValidEmail(email));
  const [isSubmitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    setValidEmail(isValidEmail(email));
  }, [email]);

  const onEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    event.persist();
    setEmail(event.target.value);
  };
  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    await applicant
      .withdraw(email)
      .then(() => setMessage(MESSAGES[MessageType.WITHDRAW_SUCCESS]))
      .catch((e) => {
        if (e.type === 'BadRequestException') {
          setMessage(MESSAGES[MessageType.BAD_REQUEST]);
        } else if (e.type === 'NotFoundException') {
          setMessage(MESSAGES[MessageType.NOT_FOUND]);
        } else {
          setMessage(MESSAGES[MessageType.FAIL]);
        }
      });
    setSubmitting(false);
  };

  const isDisabled = !hasValidEmail || isSubmitting;
  return (
    <div className="row mt-5 justify-content-center">
      <div className="container">
        <div className="lottery-sign-out">
          <LotterySignOutForm
            email={email}
            hasValidEmail={hasValidEmail}
            message={message}
            onEmailChange={onEmailChange}
            onSubmit={onSubmit}
            isDisabled={isDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export const LotterySignOutContainer = withRouter(LotterySignOut);
