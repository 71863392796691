import * as React from 'react';
import * as PropTypes from 'prop-types';
import './App.scss';
import { connect, Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Home } from './view/home/Home';
import Format from './view/format/Format';
import Values from './view/values/Values';
import Location from './view/location/Location';
import Accessibility from './view/accessibility/Accessibility';
import Imprint from './view/imprint/Imprint';
import Sponsoring from './view/sponsoring/Sponsoring';
import ScrollToTop from './view/common/ScrollToTop';
import History from './view/history/History';
import FAQ from './view/faq/FAQ';
import Header from './view/common/Header';
import Navigation from './view/common/navigation/Navigation';
import Footer from './view/common/Footer';
import NewsletterSignOutContainer from './view/newsletter-signout/NewsletterSignOutContainer';
import FourOFour from './view/common/FourOFour';
import NewsletterConfirmContainer from './view/newsletter-confirm/NewsletterConfirmContainer';
import PrivacyPolicy from './view/privacyPolicy/PrivacyPolicy';
import Application from './view/application/Application';
import { SponsorsProvider } from './view/common/SponsorsProvider';
import { Sponsors as SponsorsApi } from './view/common/api/sponsors';
import Training from './view/training/Training';
import { ConferenceProvider } from './view/common/ConferenceProvider';
import { Conferences } from './view/common/api/conferences';
import { ApplicantsProvider } from './view/common/ApplicantsProvider';
import { Applicants } from './view/common/api/applicants';
import { LotterySignOutContainer } from './view/application/sign-out/LotterySignOutContainer';
import Abstract from './view/training/Abstract';
import OnsiteQuickLinks from './view/onsiteInfos/OnsiteQuickLinks';

export type WithLayoutProps = {
  children: React.ReactNode;
};

function WithLayout(props: WithLayoutProps) {
  return (
    <div>
      <Navigation />
      {props.children}
      <Footer />
    </div>
  );
}

WithLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export type AppProps = {
  store: any;
  history: any;
};

export function App(props: AppProps): React.ReactElement<AppProps> {
  return (
    <Provider store={props.store}>
      <ConferenceProvider conferencesApi={new Conferences()}>
        <ApplicantsProvider applicantsApi={new Applicants()}>
          <SponsorsProvider sponsorsApi={new SponsorsApi()}>
            <Router history={props.history}>
              <ScrollToTop>
                <Switch>
                  <Redirect exact from="/" to="/home" />
                  <Redirect exact from="/foundations" to="/training" />
                  <Route exact path="/home">
                    <WithLayout>
                      <Header />
                      <Home />
                    </WithLayout>
                  </Route>
                  <Route exact path="/format">
                    <WithLayout>
                      <Format />
                    </WithLayout>
                  </Route>
                  <Route exact path="/training">
                    <WithLayout>
                      <Training />
                    </WithLayout>
                  </Route>
                  <Route exact path="/training/abstract/:abstract">
                    <WithLayout>
                      <Abstract />
                    </WithLayout>
                  </Route>
                  <Route exact path="/location">
                    <WithLayout>
                      <Location />
                    </WithLayout>
                  </Route>
                  <Route exact path="/onsite-quick-links">
                    <WithLayout>
                      <OnsiteQuickLinks />
                    </WithLayout>
                  </Route>
                  <Route exact path="/values">
                    <WithLayout>
                      <Values />
                    </WithLayout>
                  </Route>
                  <Route exact path="/accessibility">
                    <WithLayout>
                      <Accessibility />
                    </WithLayout>
                  </Route>
                  <Route exact path="/imprint">
                    <WithLayout>
                      <Imprint />
                    </WithLayout>
                  </Route>
                  <Route exact path="/privacy-policy">
                    <WithLayout>
                      <PrivacyPolicy />
                    </WithLayout>
                  </Route>
                  <Route exact path="/sponsoring">
                    <WithLayout>
                      <Sponsoring />
                    </WithLayout>
                  </Route>
                  <Route exact path="/application">
                    <WithLayout>
                      <Application />
                    </WithLayout>
                  </Route>
                  <Route exact path="/lottery-sign-out/:data?">
                    <WithLayout>
                      <LotterySignOutContainer />
                    </WithLayout>
                  </Route>
                  <Route exact path="/history">
                    <WithLayout>
                      <History />
                    </WithLayout>
                  </Route>
                  <Route exact path="/faq">
                    <WithLayout>
                      <FAQ />
                    </WithLayout>
                  </Route>
                  <Route exact path="/newsletter-sign-out/:data?">
                    <WithLayout>
                      <NewsletterSignOutContainer />
                    </WithLayout>
                  </Route>
                  <Route exact path="/newsletter-confirm/:data?">
                    <WithLayout>
                      <NewsletterConfirmContainer />
                    </WithLayout>
                  </Route>
                  <Route>
                    <WithLayout>
                      <FourOFour />
                    </WithLayout>
                  </Route>
                </Switch>
              </ScrollToTop>
            </Router>
          </SponsorsProvider>
        </ApplicantsProvider>
      </ConferenceProvider>
    </Provider>
  );
}

const mapStateToProps = (state: any) => {
  return { ...state };
};

export default connect(mapStateToProps)(App);
