import { call, put } from 'redux-saga/effects';
import api from '../requests/api';
import {
  addFailed,
  addSucceeded,
  confirmFailed,
  confirmSucceeded,
  removeFailed,
  removeSucceeded,
} from '../events/newsletterEvents';
type SignUpData = {
  username: string;
  email: string;
};
type SignOutData = {
  email: string;
};
type ConfirmData = {
  consentKey: string;
};
export function* signUpSaga({ username, email }: SignUpData): Iterable<any> {
  try {
    const result = yield call(api.addInterestedPerson, username, email);

    if (result) {
      yield put(addSucceeded());
    } else {
      yield put(addFailed());
    }
  } catch (_) {
    yield put(addFailed());
  }
}
export function* confirmSaga({ consentKey }: ConfirmData): Iterable<any> {
  try {
    const result = yield call(api.confirmInterestedPerson, consentKey);

    if (result) {
      yield put(confirmSucceeded());
    } else {
      yield put(confirmFailed());
    }
  } catch (_) {
    yield put(confirmFailed());
  }
}
export function* signOutSaga({ email }: SignOutData): Iterable<any> {
  try {
    const result = yield call(api.removeInterestedPerson, email);

    if (result) {
      yield put(removeSucceeded());
    } else {
      yield put(removeFailed());
    }
  } catch (_) {
    yield put(removeFailed());
  }
}
