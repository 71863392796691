import React from 'react';
import { useLocation } from 'react-router-dom';

export default function FourOFour() {
  const location = useLocation();
  return (
    <div>
      <h1>404</h1>
      <h2>Ops, sorry we cannot find {location?.pathname}!</h2>
    </div>
  );
}
