import NewsletterEvents from '../events/newsletterEvents';
import type { NewsletterEvent } from '../events/newsletterEvents';
import { EMPTY } from './NewsletterSignOutConstants';
type State = {
  message: string;
};
const INITIAL_STATE = {
  message: EMPTY,
};

const newsletterSignOutReducer = (
  state: State = INITIAL_STATE,
  event: NewsletterEvent,
) => {
  switch (event.type) {
    case NewsletterEvents.REMOVE_SUCCEEDED:
    case NewsletterEvents.REMOVE_FAILED:
      return {
        message: event.message || EMPTY,
      };

    default:
      return state;
  }
};

export default newsletterSignOutReducer;
