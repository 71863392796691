import { Link } from 'react-router-dom';
import React from 'react';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Nutshell = () => (
  <div id="nutshell">
    <div className="page-header">
      <h2>SoCraTes in a nutshell</h2>
    </div>
    <div>
      <p>
        This event is about the sustainable creation of useful software in a
        responsible way.
      </p>
    </div>
    <div className="segment-header">
      <h3>It&#39;s about People</h3>
    </div>
    <div>
      <p>
        Everyone who is concerned with coding, testing, code quality, software
        craft, if
      </p>
      <ul>
        <li>you are passionate about software development and testing</li>
        <li>
          you have heard of Software Craft or even are already part of the
          growing community
        </li>
        <li>you want to share your knowledge, experience and expertise</li>
      </ul>
    </div>
    <div className="segment-header">
      <h3>It&#39;s about Action</h3>
    </div>
    <div>
      <p>
        The event is run as a non-profit, low-cost event. It is a joint-effort
        of all{' '}
        <a
          href="http://www.softwerkskammer.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Softwerkskammer
        </a>{' '}
        groups, the community of all German-speaking Software Craft groups. It
        will be much like a retreat. We will be there for 48 hours to
        collaborate and share ideas. There will be:{' '}
      </p>
      <div className="bullet-points">
        <ul>
          <li>Highly collaborative interactions</li>
          <li>
            A self-organised{' '}
            <a
              href="http://agilecoachcamp.org/tiki-index.php?page=OpenSpace"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open Space
            </a>
            , with
            <ul>
              <li>hands-on coding sessions</li>
              <li>sessions focused on discussion</li>
              <li>interactive talks</li>
              <li>lots more</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div className="segment-header">
      <h3>It&#39;s about Fun</h3>
    </div>
    <div>
      <p>
        We will not only spend the days together but also the nights. And –
        believe it or not – programmers and testers are great company! There
        will be lots of opportunities to have fun; starting with simple
        chit-chat about hiking, to playing board games, dancing and any activity
        you can think of.{' '}
      </p>
      <p>
        <Link className="btn btn-info" to="/format/#schedule" title="Format">
          <FontAwesomeIcon icon={faClock} /> Check out the full schedule
        </Link>
      </p>
      <p>
        SoCraTes is an international conference, and the conference&#39;s
        language is English. We cordially ask you to keep all conversations in
        English, even for colloquial discussions.
      </p>
    </div>
  </div>
);

export default Nutshell;
