import React from 'react';

export default function Imprint() {
  return (
    <div>
      <div id="imprint" className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="page-header">
              <h1>Impressum</h1>
            </div>
            <div id="german_imprint">
              <p>
                SoCraTes Deutschland e.V.
                <br />
                Am Hirschanger 1<br />
                90610 Winkelhaid
              </p>

              <p>
                Vereinsregister: VR 202906
                <br />
                Registergericht: Amtsgericht N&uuml;rnberg
              </p>

              <p>
                <strong>Vertreten durch:</strong>
                <br />
                G&ouml;schel, Tobias
                <br />
                Schneider, Reiko
                <br />
                Soler Sanandres, Alexandre
              </p>

              <h4>Kontakt</h4>
              <p>
                Telefon: 09187 7061771
                <br />
                Telefax: 09187 7064027
                <br />
                E-Mail: info@socrates-conference.de
              </p>

              <h4>Umsatzsteuer-ID</h4>
              <p>
                Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
                Umsatzsteuergesetz:
                <br />
                DE365550294
              </p>

              <h4>Redaktionell verantwortlich</h4>
              <p>
                Alexandre Soler Sanandres
                <br />
                Am Hirschanger 1<br />
                90610 Winkelhaid
              </p>

              <h4>EU-Streitschlichtung</h4>
              <p>
                Die Europ&auml;ische Kommission stellt eine Plattform zur
                Online-Streitbeilegung (OS) bereit:{' '}
                <a
                  href="https://ec.europa.eu/consumers/odr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://ec.europa.eu/consumers/odr/
                </a>
                .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
              </p>

              <h4>
                Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
              </h4>
              <p>
                Wir sind nicht bereit oder verpflichtet, an
                Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen.
              </p>
            </div>
            <br />
            <hr />
            <br />
            <div className="page-header">
              <h1>Imprint / Legal Notice</h1>
            </div>
            <div id="english_imprint">
              <p>
                SoCraTes Deutschland e.V.
                <br />
                Am Hirschanger 1<br />
                90610 Winkelhaid
              </p>

              <p>
                Register of Associations: VR 202906
                <br />
                Registration court: Amtsgericht N&uuml;rnberg
              </p>

              <p>
                <strong>Represented by:</strong>
                <br />
                G&ouml;schel, Tobias
                <br />
                Schneider, Reiko
                <br />
                Soler Sanandres, Alexandre
              </p>

              <h4>Contact</h4>
              <p>
                Phone: 09187 7061771
                <br />
                Telefax: 09187 7064027
                <br />
                E-mail: info@socrates-conference.de
              </p>

              <h4>VAT ID</h4>
              <p>
                Sales tax identification number according to Sect. 27 a of the
                Sales Tax Law:
                <br />
                DE365550294
              </p>

              <h4>Person responsible for editorial</h4>
              <p>
                Alexandre Soler Sanandres
                <br />
                Am Hirschanger 1<br />
                90610 Winkelhaid
              </p>

              <h4>EU dispute resolution</h4>
              <p>
                The European Commission provides a platform for online dispute
                resolution (ODR):{' '}
                <a
                  href="https://ec.europa.eu/consumers/odr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://ec.europa.eu/consumers/odr/
                </a>
                .<br /> Our e-mail address can be found above in the site
                notice.
              </p>

              <h4>
                Dispute resolution proceedings in front of a consumer
                arbitration board
              </h4>
              <p>
                We are not willing or obliged to participate in dispute
                resolution proceedings in front of a consumer arbitration board.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
