import React from 'react';
import { PartnerConference } from './partnerConferences.types';
type Props = PartnerConference;
export default function PartnerConferenceComponent({
  url,
  name,
  description,
}: Props) {
  return (
    <p>
      <a href={url} target="_blank" rel="noreferrer">
        {name}
      </a>
      <br />
      <small>{description}</small>
    </p>
  );
}
