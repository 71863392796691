import React, { ChangeEventHandler, FormEventHandler } from 'react';
import { RoomTypeInput } from './RoomTypeInput';
import { NicknameInput } from './NicknameInput';
import { EmailInput } from './EmailInput';
import { DiversityInput } from './DiversityInput';
import { Applicant, RoomType } from '../../common/types';
import { DataPrivacyInput } from './DataPrivacyInput';
import { DiversityDescription } from './DiversityDescription';
import {
  checkboxValidationClassName,
  validationClassName,
} from '../../../utils/validation';

type Handlers = {
  onNicknameChange: ChangeEventHandler<HTMLInputElement>;
  onEmailChange: ChangeEventHandler<HTMLInputElement>;
  onRoommateChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  onRoomTypeChange: ChangeEventHandler<HTMLInputElement>;
  onDiversityChange: (choice: string) => void;
  onPrivacyStatementChange: ChangeEventHandler<HTMLInputElement>;
};

type ApplicationFormProps = {
  handlers: Handlers;
  validated: Record<string, boolean>;
  isSubmitBtnEnabled: boolean;
  roomTypeOptions?: RoomType[];
  values: Applicant;
};

export function ApplicationForm(props: ApplicationFormProps) {
  const {
    email,
    diversitySelected,
    name: nickname,
    roommate,
    roomTypeSelected,
  } = props.values;
  const {
    name: validNickname,
    email: validEmail,
    roomTypesSelected: validRoommate,
    privacyStatementAcknowledged,
  } = props.validated;
  const {
    onEmailChange,
    onNicknameChange,
    onRoomTypeChange,
    onDiversityChange,
    onPrivacyStatementChange,
    onRoommateChange,
    onSubmit,
  } = props.handlers;
  return (
    <form className="form card mt-5 " id="application-form" onSubmit={onSubmit}>
      <div className="card-header">
        <h4 className="mt-2">Apply for the lottery</h4>
      </div>
      <div className={'card-body pt-3'}>
        <div className={'form-info text-muted form-text'}>
          <h4 className={'mb-0'}>Personal Information</h4>
        </div>
      </div>
      <div className="card-body mt-0">
        <NicknameInput
          validationClass={validationClassName(validNickname)}
          value={nickname}
          onChange={onNicknameChange}
        />
        <EmailInput
          validationClass={validationClassName(validEmail)}
          value={email}
          onChange={onEmailChange}
        />
      </div>
      <hr />
      <div className={'card-body pt-0'}>
        <div className={'form-info text-muted form-text'}>
          <h4 className={'mb-0'}>Room Choice</h4>
          <a href="#faq-item2">(Click here for more info)</a>
        </div>
      </div>
      <div className="card-body">
        <RoomTypeInput
          options={props.roomTypeOptions}
          selected={roomTypeSelected}
          roommate={roommate}
          onRoommateChange={onRoommateChange}
          onChange={onRoomTypeChange}
          validationClass={validationClassName(validRoommate)}
        />
      </div>
      <hr />
      <div className="card-body pt-0">
        <div className="diversity-info text-muted form-text">
          <h4>Diversity Status</h4>
        </div>
      </div>
      <div className="card-body">
        <DiversityDescription />
        <DiversityInput
          diversitySelected={diversitySelected}
          onDiversityChange={onDiversityChange}
        />
      </div>
      <hr />
      <div className="card-body pt-0">
        <DataPrivacyInput
          checked={privacyStatementAcknowledged ?? false}
          onChange={onPrivacyStatementChange}
          validationClass={checkboxValidationClassName(
            privacyStatementAcknowledged,
          )}
        />
      </div>
      <div className="card-footer pb-4 pt-4">
        <button
          className="start-0 btn btn-primary mt-0"
          disabled={!props.isSubmitBtnEnabled}
          id="submit-application"
          type="submit"
        >
          I really want to participate!
        </button>
        <div className="start-0 col-form-label mt-2 ms-4 align-middle">
          (You cannot register twice, please make sure your data is correct.)
        </div>
      </div>
    </form>
  );
}
