import { MessageType } from './form/applicationMessages';
import React from 'react';

type MessageProps = {
  messageType: MessageType;
  messageHtml: string;
};

export function Message(props: MessageProps) {
  const { messageType, messageHtml } = props;
  const msgClass =
    messageType === MessageType.SUCCESS ? 'alert-success' : 'alert-danger';
  return (
    <div className={'row'}>
      <div className={'col-md-3'}></div>
      <div className={'col-md-6'}>
        <div
          id="application-message"
          className={'alert m-5 p-5 ' + msgClass}
          role="alert"
        >
          <h3>
            {messageType === MessageType.SUCCESS
              ? "You're registered!"
              : 'Registration failed.'}
          </h3>
          <p
            className={'mt-4'}
            dangerouslySetInnerHTML={{
              __html: messageHtml.replaceAll('\n', '<br />'),
            }}
          ></p>
        </div>
      </div>
      <div className={'col-md-3'}></div>
    </div>
  );
}
