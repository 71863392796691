import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// @ts-ignore
import global from 'global';
import rootSaga from './view/sagas/rootSaga';
import StoreFactory, { HistoryFactory } from './view/store/store';
import { unregister } from './registerServiceWorker';
import rootReducer from './view/reducers/rootReducer';
import './index.scss';

const history = HistoryFactory.createHistory();
const store = StoreFactory.createStore(rootReducer, rootSaga, history);
if (process.env.NODE_ENV === 'development') {
  console.info(
    '%cAttaching store to global scope. You can access it in the console using the variable %cstore%c.',
    'color: gray; font-weight: lighter;',
    'font-weight: bold;',
    'font-weight: inherit;',
  );
  global.store = store;
}

// @ts-ignore: there will always be a document
ReactDOM.render(
  <App store={store} history={history} />,
  document.getElementById('root'),
);
unregister();
