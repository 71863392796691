import { all, put, takeEvery } from 'redux-saga/effects';
import RoutingCommand from '../commands/routingCommand';
import { push } from 'react-router-redux';
import NewsletterCommand from '../commands/newsletterCommand';
import { confirmSaga, signOutSaga, signUpSaga } from './newsletterSaga';

function* routingToSaga(action: any) {
  yield put(push(action.url));
}

function* rootSaga(): any {
  yield all([
    takeEvery(RoutingCommand.ROUTE_TO, routingToSaga),
    // @ts-ignore
    takeEvery(NewsletterCommand.SIGN_UP, signUpSaga),
    // @ts-ignore
    takeEvery(NewsletterCommand.SIGN_OUT, signOutSaga),
    // @ts-ignore
    takeEvery(NewsletterCommand.CONFIRM, confirmSaga),
  ]);
}

export default rootSaga;
