import { NavLink } from 'react-router-dom';
import React from 'react';
import './Navigation.scss';
type NavigationLinkProps = {
  url: string;
  title: string;
  icon: JSX.Element;
};
export default function NavigationLink(props: NavigationLinkProps) {
  return (
    <li className="nav-item">
      <NavLink
        className="nav-link"
        to={props.url}
        title={props.title}
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <span className="nav-link-icon">{props.icon}</span>
        <span className="nav-link-text d-md-none d-lg-none d-xl-inline">
          {' '}
          {props.title}
        </span>
      </NavLink>
    </li>
  );
}
