import socratesPicture from '../../assets/img/socrates2014_about_wall.jpg';
import React from 'react';
import { LogoColumn } from '../common/LogoColumn';
export default function Format() {
  return (
    <div id="format" className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-header">
            <h1>SoCraTes</h1>
          </div>
          <div className="extraspace">
            <img
              className="img-fluid"
              src={socratesPicture}
              alt="A session at SoCraTes."
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="row">
            <div className="col-xs-12">
              <div className="page-header">
                <h3>What it is all about</h3>
              </div>
              <div>
                <p>
                  SoCraTes is an international non-profit and community-run
                  (un)conference about all things related to{' '}
                  <a
                    href="https://en.wikipedia.org/wiki/Software_craftsmanship"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Software Craft
                  </a>
                  . And at the same time it is so much more.{' '}
                </p>
              </div>
              <div className="segment-header">
                <h3>Format</h3>
              </div>
              <div>
                <p>
                  The conference starts with a{' '}
                  <a
                    href="https://en.wikipedia.org/wiki/World_caf%C3%A9_(conversation)"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    World Café
                  </a>{' '}
                  followed by two days of{' '}
                  <a
                    href="http://agilecoachcamp.org/tiki-index.php?page=OpenSpace"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open Space
                  </a>{' '}
                  and finishes with a full day of workshops.{' '}
                </p>
                <p>
                  Open Space is at the core of what SoCraTes is. Each day starts
                  with everybody gathering in the same room and creating the
                  schedule for the day. Anybody who wants to may grab a sheet of
                  paper and write the topic they want to add to the schedule.
                  Then the topics are briefly presented and assigned to a
                  timeslot and a room. That&#39;s it for a start, next come the
                  sessions.{' '}
                </p>
                <p>
                  There are many different kinds of sessions. Some sessions are
                  all about discussing the topic in a somewhat chaotic manner
                  where even the participants don&#39;t know where the session
                  is going to take them. People share opinions, ask questions
                  and compare experiences. Not everybody needs to share -
                  interest in the topic alone is sufficient. This is a great way
                  to learn beyond the book material, to learn how something
                  works in the real world from concrete experiences people had
                  in their specific contexts.{' '}
                </p>
                <p>
                  Some sessions express the desire of the person proposing the
                  session to learn about a certain topic. In many cases, there
                  will be some participants who know the topic well and who are
                  willing to share their knowledge.{' '}
                </p>
                <p>
                  A smaller number of sessions are run as presentations. This
                  can be about TDD, Event Sourcing, Functional Programming, a
                  particular library, or anything else a talk can be about.
                  There is always time left for discussion and answering
                  questions.{' '}
                </p>
                <p>
                  Quite a few sessions focus on hands-on activities. You want to
                  try a new technology? Or just hack together in a group?
                  You&#39;ll certainly find people to join you. Some of the
                  hands-on sessions are run like workshops, some are done in
                  ensemble-programming format, some are code katas, others are
                  just hacking on an interesting open-source library. It can
                  really be anything the group want it to be.{' '}
                </p>
                <p>
                  And this is the beauty of Open Space - it really will be
                  anything the group wants it to be. You are feeling a bit
                  frustrated with something at work? How about a therapy session
                  outside in the sun with a few like-minded people over a glass
                  of wine or a cup of tea? You have a particular question about
                  something you&#39;re stuck with? Just ask for help. You get
                  the picture by now. :){' '}
                </p>
                <p>And this leads us to... </p>
              </div>
              <div className="segment-header">
                <h3>People</h3>
              </div>
              <div>
                <p>
                  SoCraTes is all about people. There are going to be 200
                  motivated, intelligent and friendly participants, just like
                  yourself, to make the best of the time together.{' '}
                </p>
                <p>
                  You&#39;ll have breakfast, lunch and dinner together. Some of
                  the best conversations happen over a meal. You&#39;ll spend
                  the evenings together in a bar, play games together, go for a
                  morning run or a walk together. There&#39;s definitely going
                  to be a game or 100 of werewolf, fun rounds of beach
                  volleyball and much more.{' '}
                </p>
                <p>
                  We all stay at the same location for the sole reason of having
                  enough time with each other - enough time to get to know each
                  other, learn from each other, create business contacts,
                  recuperate from the real world and have a generally great
                  time. If you&#39;ve never been to a conference like this, it
                  will be an eye-opener. No more divisions between speakers and
                  audience, going home alone or being tied to a fixed schedule.
                  Doesn&#39;t matter if you&#39;re a world-class expert,
                  world-weary salary-earner, a student, or have just started to
                  program a couple of months ago. Everybody has something to
                  give, to share and to learn from others. And all of that
                  happens in a friendly, supportive and safe environment that
                  embraces and celebrates diversity.{' '}
                </p>
                <p>
                  This can sound a bit unusual, but it works beautifully in
                  practice. Why? Because so many amazing people are at the same
                  place, focusing on each other. By the end of the conference
                  you&#39;ll know by name a few dozens of new faces. You&#39;ll
                  have made friends, business connections, if you want them,
                  maybe you&#39;ll find a mentor, or a kindred spirit for that
                  interesting project you want to work on. Anything can happen.
                  And is bound to happen.{' '}
                </p>
                <p>
                  And<b> that</b> is what SoCraTes is really all about.{' '}
                </p>
              </div>
              <div className="segment-header">
                <h3>Language</h3>
              </div>
              <p>
                We are an international conference, and the conference language
                is English. To help non-German participants enjoy all
                conversations during the conference, we encourage everyone to
                speak English even in the breaks.
              </p>
              <div className="segment-header">
                <h3 id="schedule">
                  👉{' '}
                  <a href="https://de.scrts.de/" title="Online Schedule">
                    Schedule
                  </a>{' '}
                  👈
                </h3>
              </div>
              <div>
                <p>Here's what a typical SoCraTes will look like: </p>
                <dl>
                  <dt>Thursday</dt>
                  <dd>
                    Following the success of the Foundations Day in 2022 we will
                    have a <a href="/training">Training Day</a> on Thursday
                    before the Open Space.
                  </dd>
                  <dd>
                    Check in for the{' '}
                    <a
                      href="http://agilecoachcamp.org/tiki-index.php?page=OpenSpace"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open Space
                    </a>{' '}
                    is expected to open at 4pm on Thursday. We will inform you
                    via Slack, as soon as we are ready to begin.
                  </dd>
                  <dd>
                    The conference starts at 5pm. This is the time to get to
                    know new faces or meet old friends, have some drinks at the
                    bar, explore the location etc. T-Shirts are being
                    distributed.
                  </dd>
                  <dd>
                    Starting around 6pm the hotel will serve a buffet so that
                    even people who arrived a little later will get something to
                    eat.
                  </dd>
                  <dd>
                    Later around 7.30pm we will officially start the conference
                    with a{' '}
                    <a
                      href="https://en.wikipedia.org/wiki/The_World_Cafe"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      World Café
                    </a>
                    , to help kickstart everyone into a great conference
                    experience.
                  </dd>
                  <dt>Friday</dt>
                  <dd>
                    Friday is completely reserved for a full-day{' '}
                    <a
                      href="http://agilecoachcamp.org/tiki-index.php?page=OpenSpace"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open Space
                    </a>
                    .
                  </dd>
                  <dd>
                    In the morning, we kick off with a marketplace, facilitated
                    by our outstanding facilitator{' '}
                    <a
                      href="https://www.linkedin.com/in/juke"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Juke
                    </a>
                    , followed by the first set of sessions. The sessions will
                    take us through the whole day, and in the evening we briefly
                    get together for a joint reflection on the day.
                  </dd>
                  <dd>
                    In the evening, there is a dinner buffet available, so
                    everyone can start into the evening at their own pace. Of
                    course there&#39;s lots of room for shared evening
                    activities like board games, making music, hacking or just
                    talking and having fun.
                  </dd>
                  <dd>
                    <table className="schedule w-100">
                      <tr className="break">
                        <td className="time">07:00 – 10:00</td>
                        <td className="session">Breakfast</td>
                      </tr>
                      <tr>
                        <td className="time">09:00 – 10:00</td>
                        <td className="session">Opening & Marketplace</td>
                      </tr>
                      <tr>
                        <td className="time">10:00 – 11:00</td>
                        <td className="session">Session 1</td>
                      </tr>
                      <tr className="break">
                        <td className="time">11:00 – 11:15</td>
                        <td className="session"> Coffee Break</td>
                      </tr>
                      <tr>
                        <td className="time">11:15 – 12:15</td>
                        <td className="session">Session 2</td>
                      </tr>
                      <tr className="break">
                        <td className="time">12:15 – 13:30</td>
                        <td className="session">Lunch</td>
                      </tr>
                      <tr>
                        <td className="time">14:00 – 15:00</td>
                        <td className="session">Session 3</td>
                      </tr>
                      <tr>
                        <td className="time">15:00 – 16:00</td>
                        <td className="session">Session 4</td>
                      </tr>
                      <tr className="break">
                        <td className="time">16:00 – 16:15</td>
                        <td className="session">Coffee Break</td>
                      </tr>
                      <tr>
                        <td className="time">16:15 – 17:15</td>
                        <td className="session">Session 5</td>
                      </tr>
                      <tr>
                        <td className="time">17:15 – 17:45</td>
                        <td className="session">Evening News</td>
                      </tr>
                      <tr className="break">
                        <td className="time">18:00</td>
                        <td className="session">Dinner</td>
                      </tr>
                    </table>
                  </dd>
                  <dt>Saturday</dt>
                  <dd>
                    Saturday is also completely reserved for a full-day{' '}
                    <a
                      href="http://agilecoachcamp.org/tiki-index.php?page=OpenSpace"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open Space
                    </a>
                    .
                  </dd>
                  <dd>
                    The day runs in the same manner as Friday, but ends with the
                    closing of the conference. This will most likely end around
                    6.15pm.
                  </dd>
                  <dd>
                    Participants staying until Sunday (or longer) will have
                    dinner at the venue starting at 6.30pm.
                  </dd>
                  <dd>
                    <table className="schedule w-100">
                      <tr className="break">
                        <td className="time">07:00 – 10:00</td>
                        <td className="session">Breakfast</td>
                      </tr>
                      <tr>
                        <td className="time">09:00 – 10:00</td>
                        <td className="session">Opening & Marketplace</td>
                      </tr>
                      <tr>
                        <td className="time">10:00 – 11:00</td>
                        <td className="session">Session 1</td>
                      </tr>
                      <tr className="break">
                        <td className="time">11:00 – 11:15</td>
                        <td className="session">Coffee Break</td>
                      </tr>
                      <tr>
                        <td className="time">11:15 – 12:15</td>
                        <td className="session">Session 2</td>
                      </tr>
                      <tr className="break">
                        <td className="time">12:15 – 13:30</td>
                        <td className="session">Lunch</td>
                      </tr>
                      <tr>
                        <td className="time">14:00 – 15:00</td>
                        <td className="session">Session 3</td>
                      </tr>
                      <tr>
                        <td className="time">15:00 – 16:00</td>
                        <td className="session">Session 4</td>
                      </tr>
                      <tr className="break">
                        <td className="time">16:00 – 16:15</td>
                        <td className="session">Coffee Break</td>
                      </tr>
                      <tr>
                        <td className="time">16:15 – 17:15</td>
                        <td className="session">Session 5</td>
                      </tr>
                      <tr>
                        <td className="time">17:15 – 18:15</td>
                        <td className="session">Evening News &amp; Closing</td>
                      </tr>
                      <tr className="break">
                        <td className="time">18:15</td>
                        <td className="session">Dinner</td>
                      </tr>
                    </table>
                  </dd>
                  <dt>Sunday</dt>
                  <dd>Sunday is reserved for workshop oriented formats.</dd>
                  <dd>
                    Each workshop can choose when to start and when to end as
                    long as it&#39;s not later than 5pm.
                  </dd>
                  <dd>
                    <table className="schedule w-100">
                      <tr className="break">
                        <td className="time">07:00 – 10:00</td>
                        <td className="session">Breakfast</td>
                      </tr>
                      <tr>
                        <td className="time">10:00 – 10:30</td>
                        <td className="session">
                          Opening &amp; Workshop Marketplace
                        </td>
                      </tr>
                      <tr>
                        <td className="time">10:30 – 11:30</td>
                        <td className="session">Workshops</td>
                      </tr>
                      <tr className="break">
                        <td className="time">11:30 – 11:45</td>
                        <td className="session">Coffee Break</td>
                      </tr>
                      <tr>
                        <td className="time">11:45 – 12:30</td>
                        <td className="session">Workshops</td>
                      </tr>
                      <tr className="break">
                        <td className="time">12:30 – 13:30</td>
                        <td className="session">Lunch</td>
                      </tr>
                      <tr>
                        <td className="time">13:30 – 15:30</td>
                        <td className="session">Workshops</td>
                      </tr>
                      <tr className="break">
                        <td className="time">15:30 – 15:45</td>
                        <td className="session">Coffee Break</td>
                      </tr>
                      <tr>
                        <td className="time">15:45 – 17:00</td>
                        <td className="session">Workshops</td>
                      </tr>
                    </table>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <LogoColumn />
      </div>
    </div>
  );
}
