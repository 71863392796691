import React from 'react';
type Props = {
  title: string;
  visible: boolean;
};
export default function NavigationApplicationLink({ title, visible }: Props) {
  return (
    <li className={'nav-item' + (visible ? ' d-block' : ' d-none')}>
      <a
        title={title}
        className="btn btn-primary pull-right btn-application"
        href="/application"
      >
        Apply for a ticket!
      </a>
    </li>
  );
}
