import { PartnerBadge } from './ConferenceBuddy/PartnerBadge';
import Sponsoring from './sponsoring/Sponsoring';

export const LogoColumn = () => (
  <div className="logocolumn col-sm-4 col-md-3 col-lg-4 col-md-offset-1 col-lg-offset-0">
    <div className="sidebar">
      <Sponsoring />
      <PartnerBadge />
    </div>
  </div>
);
