/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import Accordion from './Accordion';
import splitArrayIntoTwoParts from '../../../utils/splitArrayIntoTwoParts';
import { Conference } from '../../common/types';
import {
  calculateSponsorableAmount,
  calculateTicket,
  Position,
  TicketOrder,
} from '../../common/calculation';
import { ensure } from '../../../utils/ensure';
import FAQItem from '../../faq/FAQItem';

type CalculationPositionProps = {
  position: Position;
};
function CalculationPosition({ position }: CalculationPositionProps) {
  return (
    <tr>
      <td>{position.label}</td>
      <td className="numeric">{position.price}</td>
    </tr>
  );
}

function PricingCalculation(props: {
  order?: TicketOrder;
  sponsoringAmount: string;
}) {
  if (props.order) {
    const ticket = calculateTicket(props.order, props.sponsoringAmount);
    return (
      <table className="pricing-example mt-4 mb-4">
        <tbody>
          {ticket.packages.map((p, i) => (
            <CalculationPosition key={i} position={p} />
          ))}
          <CalculationPosition position={ticket.accommodation} />
          <tr>
            <td className="total">Total, no sponsoring</td>
            <td className="numeric total">{ticket.total}</td>
          </tr>
          <tr>
            <td className="total-light">Total, full sponsoring</td>
            <td className="numeric total-light">
              {ticket.totalWithSponsoring}
            </td>
          </tr>
        </tbody>
      </table>
    );
  } else {
    return <div />;
  }
}

const entries = (conference: Conference, order: TicketOrder) => [
  {
    title: 'How it works',
    content: (
      <div>
        <p>Tickets will be given out in a lottery.</p>
        <p>
          The registration will open a couple of months before the conference.
          All registrations (except for guaranteed sponsor slots) end up on the
          waiting list. It will not matter, if you register on the first day, or
          on the last. A few weeks after the registration opens, or when the
          number of applicants reaches the location's capacity, there will be
          lottery draws to distribute available slots to people on the waiting
          list.
        </p>
        <p>
          Please note that the draws will be (pseudo) random, and we will not
          influence the results in any way.
        </p>
        <p>After each draw, you will be notified, if you are in.</p>
        <p>
          If you are not, you stay on the waiting list (as was previous
          practice). But don’t give up just yet: Cancellations and other issues
          always cause some slots to become available again, and we will run
          additional draws to give those away at regular intervals.
        </p>
      </div>
    ),
  },
  {
    title: 'Ticket Options',
    content: (
      <div>
        <p>We offer three packages</p>
        <ul>
          <li>
            <Link to={'/training'}>Training Day</Link>
            <br />
            <small>
              (Wednesday evening or Thursday morning, until Thursday evening)
            </small>
          </li>
          <li>
            <Link to={'/format'}>SoCraTes Unconference</Link>
            <br />
            <small>
              (Thursday evening until Saturday evening, or Sunday morning)
            </small>
          </li>
          <li>
            Workshop Day (incl. code retreat)
            <br />
            <small>(Sunday, until the evening, or Monday morning)</small>
          </li>
        </ul>
        <p>The price for your stay will be per person and include:</p>
        <ul>
          <li>Participation in the respective event</li>
          <li>Your hotel room</li>
          <li>Meals, snacks, and non-alcoholic beverages (including coffee)</li>
        </ul>
        <p>
          The exact amount depends on your choice of packages - and the support
          from our sponsors may make it even lower than announced here.
        </p>
      </div>
    ),
  },
  {
    title: 'Room Choice',
    content: (
      <div>
        <p>You can select as many room types as you like. </p>
        <p>
          If you select more than one room type, this means you are happy with
          any of the room types you selected. We will move you to one of the
          rooms, as they become available.{' '}
        </p>
        <p>
          Generally speaking: The more room options you select, the better your
          chances are to get a spot.
        </p>
        <p>A junior room is just a smaller room than a standard room.</p>
        <p>
          However, please note that shared rooms (both junior double and double)
          may only be selected, if you name your roommate at registration. In
          fact, in order to be drawn for a shared room at the lottery, both
          roommates must be registered and have named each other. In the past,
          you could freely choose a roommate until right before the conference -
          but due to COVID-19, this is no longer possible. We still want to
          allow friends, couples, or families to attend SoCraTes together. But
          we will not facilitate or encourage random pairings.
        </p>
        <p>
          Please also be aware that we will move you to the next available room
          without checking back with you first. So please select only those room
          types that you are happy with!
        </p>
      </div>
    ),
  },
  {
    title: 'Payment and Invoice',
    content: (
      <div>
        <p>
          We will issue an invoice for advance payment of the full amount (as
          given below), approximately two weeks ahead of the conference.
        </p>
        <p>
          The final amount will include food, group rooms and free non-alcoholic
          drinks, plus the hotel service fee for the conference (at most{' '}
          {calculateSponsorableAmount(order)}, if you stay from Thursday until
          Sunday, all of which will be covered by sponsoring money, if
          possible), and the price for Training Day - as well as your hotel room
          (see pricing table below).
        </p>
        <table className="pricing mt-4 mb-4">
          <thead>
            <tr>
              <th>Event</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {conference.flatFees?.map((f) => (
              <tr key={f.type}>
                <td>{f.description}</td>
                <td className="numeric">{f.price}</td>
              </tr>
            ))}
          </tbody>
          <thead>
            <tr>
              <th>Room type</th>
              <th>Price p. person / nt</th>
            </tr>
          </thead>
          <tbody>
            {conference.roomTypes?.map((r) => (
              <tr key={r.type}>
                <td>{r.description}</td>
                <td className="numeric">{r.pricePerNight}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>
          To give you an example: The price for attending all three events, from
          Thursday to Monday in a single room will be:
        </p>
        {conference?.flatFees && conference?.roomTypes && (
          <PricingCalculation
            order={order}
            sponsoringAmount={calculateSponsorableAmount(order)}
          />
        )}
        <p>
          Disclaimer: We can't say for sure how much sponsoring we can collect,
          until all the numbers are in - but there's a high likelihood of the
          sum being much closer to the lower end.
        </p>
      </div>
    ),
  },
  {
    title: 'I won the lottery, what now?',
    content: (
      <div>
        <p>
          Please choose your event packages and verify the duration of your
          stay. If anything needs to be changed, send an email to
          registration@socrates-conference.de, along with your desired changes.
        </p>
        <p>
          To book until Sunday evening (or Monday morning) means participation
          in workshops or the code retreat. Since sponsorship money is being
          used to make this possible, please be fair and do not specify Workshop
          Day, unless you really want to participate in these activities.
        </p>
        <p>
          You may specify your preferred size for a SoCraTes t-shirt in your
          attendee profile. If you don’t want a t-shirt, please keep the “no
          swag” option (the default) - to minimize our carbon footprint, and to
          make the best use of sponsoring money.
        </p>
        <p>
          Please enter your home and billing addresses. Also, you may tell us
          about your food preferences / dietary restrictions, whether you have
          any special needs, or any other information you want to share with us.
        </p>
        <p>
          Please make sure that your registration information stays up to date
          until the conference is over.
        </p>
      </div>
    ),
  },
];

type ApplicationDescriptionProps = {
  conference?: Conference;
};
export default function ApplicationDescription({
  conference,
}: ApplicationDescriptionProps) {
  if (conference) {
    const order: TicketOrder = {
      flatFeeItems: [...ensure(conference?.flatFees)],
      roomType: ensure(conference?.roomTypes?.find((r) => r.type === 'single')),
      arrival: '2022-08-25',
      departure: '2022-08-29',
    };
    const twoColumnEntries = splitArrayIntoTwoParts(entries(conference, order));
    const accordionId = 'registration-accordion';

    return (
      <div>
        <div className="row">
          <div className="col-md-12 m-5">
            <h4 className="mt-4">
              Do you want to skip the lottery and get a secured spot for
              SoCraTes? Become a <Link to="/sponsoring">sponsor</Link>.
            </h4>
          </div>
        </div>
        <div className="row mb-8" id={accordionId}>
          <div className="col-12 col-md-6">
            {twoColumnEntries[0].map((entry, index) => (
              <FAQItem
                key={index}
                content={entry.content}
                title={entry.title}
                index={index}
              />
            ))}
          </div>
          <div className="col-12 col-md-6">
            {twoColumnEntries[1].map((entry, index) => (
              <FAQItem
                key={index}
                content={entry.content}
                title={entry.title}
                index={twoColumnEntries[0].length + index}
              />
            ))}
          </div>
        </div>
      </div>
    );
  } else return <div />;
}
