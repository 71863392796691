import React, { Component } from 'react';
import { connect } from 'react-redux';
import isValidEmailFormat from '../../../utils/isValidEmailFormat';
import { EMPTY } from './NewsletterSignUpConstants';
import NewsletterSignUpForm from './NewsletterSignUpForm';
import { signUp } from '../../commands/newsletterCommand';
export type NewsletterSignUpContainerProps = {
  message?: string;
  signUp: (username: string, email: string) => any;
};
type State = {
  name: string;
  email: string;
  hasValidName: boolean;
  hasValidEmail: boolean;
  isDataPrivacyConfirmedChecked: boolean;
  isSubmitting: boolean;
  message: string;
};
const initialState = {
  name: EMPTY,
  email: EMPTY,
  hasValidName: false,
  hasValidEmail: false,
  isDataPrivacyConfirmedChecked: false,
  isSubmitting: false,
  message: EMPTY,
};
export class NewsletterSignUpContainer extends Component<
  NewsletterSignUpContainerProps,
  State
> {
  state = initialState;

  static getDerivedStateFromProps(
    nextProps: NewsletterSignUpContainerProps,
    prevState: State,
  ) {
    return { ...prevState, ...nextProps };
  }

  onSubmit = () => {
    this.setState({ ...this.state, isSubmitting: true, message: EMPTY }, () =>
      this.props.signUp(this.state.name, this.state.email),
    );
  };
  onNameChange = (name: string) => {
    const hasValidName = name.length !== 0;
    this.setState({ ...this.state, name, hasValidName });
  };
  onEmailChange = (email: string) => {
    const hasValidEmail = email.length !== 0 && isValidEmailFormat(email);
    this.setState({ ...this.state, email, hasValidEmail });
  };
  onDataPrivacyConfirmedChange = () => {
    this.setState({
      ...this.state,
      isDataPrivacyConfirmedChecked: !this.state.isDataPrivacyConfirmedChecked,
    });
  };

  render() {
    const {
      name,
      hasValidName,
      email,
      hasValidEmail,
      isSubmitting,
      message,
      isDataPrivacyConfirmedChecked,
    } = this.state;
    const isDisabled =
      !hasValidEmail ||
      !hasValidName ||
      isSubmitting ||
      !isDataPrivacyConfirmedChecked;
    return (
      <div id="newsletter">
        <NewsletterSignUpForm
          name={name}
          hasValidName={hasValidName}
          email={email}
          hasValidEmail={hasValidEmail}
          message={message}
          onNameChange={this.onNameChange}
          onEmailChange={this.onEmailChange}
          onSubmit={this.onSubmit}
          isDisabled={isDisabled}
          isDataPrivacyConfirmedChecked={isDataPrivacyConfirmedChecked}
          dataPrivacyConfirmedChange={this.onDataPrivacyConfirmedChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({ ...state.newsletter });

const mapDispatchToProps = {
  signUp,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewsletterSignUpContainer);
