import React from 'react';

const Covid = () => (
  <div id="covid">
    <div className="segment-header">
      <h3>COVID-19 Policy</h3>
    </div>
    <div>
      <p>
        Although COVID-19 has now largely disappeared from the news, and
        statistics are no longer maintained properly, there are still a
        noticeable number of infections in Germany every day. We intend to keep
        attendees safe during the conference and have decided to keep in place
        our COVID measures:
      </p>
      <ul>
        <li>
          Attendees are kindly requested to take a provided lateral flow test
          upon arrival. You will not be allowed on the premise otherwise and
          will be charged a night's stay as per hotel policy.
        </li>
        <li>
          Additionally, attendees will receive one lateral flow test for each
          day. We trust you to inform the organisers in case you test positive.
        </li>
        <li>
          We recommend attendees bring and wear FFP2 masks indoors, but we will
          not enforce the wearing of masks.
        </li>
      </ul>
      <p>Thank you for your cooperation!</p>
    </div>
  </div>
);

export default Covid;
