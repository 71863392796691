import React, { MouseEventHandler } from 'react';
import './newsletterConfirm.scss';
import ExternalLink from '../common/ExternalLink';

export type Props = {
  email: string;
  hasValidEmail: boolean;
  onEmailChange: (email: string) => void;
  consentKey: string;
  dataPrivacyConfirmedChange: () => void;
  isNewsletterConfirmedChecked: boolean;
  isDataPrivacyConfirmedChecked: boolean;
  message: string;
  newsletterConfirmedChange: () => void;
  onConsentKeyChange: (value: string) => void;
  onNameChange: (value: string) => void;
  onSubmit: () => void;
  isDisabled: boolean;
};
export default function NewsletterForm(props: Props) {
  const klass = (isValid: boolean) =>
    `form-control mb-2 ${isValid ? 'is-valid' : 'is-invalid'}`;

  const checkklass = (isValid: boolean) =>
    `form-control ${isValid ? 'is-valid' : 'is-invalid'}`;

  const consentKeyClass = klass(props.consentKey !== '');
  const newsletterConfirmedClass = checkklass(
    props.isNewsletterConfirmedChecked,
  );
  const dataPrivacyConfirmedClass = checkklass(
    props.isDataPrivacyConfirmedChecked,
  );

  const onConsentKeyChange = (
    event: React.SyntheticEvent<HTMLInputElement>,
  ) => {
    props.onConsentKeyChange(event.currentTarget.value);
  };

  const onSubmit: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    props.onSubmit();
  };

  const renderMessage = () => {
    if (props.message && props.message !== '') {
      return (
        <div id="newsletter-message" className="pulse">
          {props.message}
        </div>
      );
    }

    return '';
  };

  return (
    <div>
      <div className="segment-header">
        <h1>Newsletter Confirmation</h1>
      </div>
      <form className="form">
        <div className="row">
          <div className="col-lg-1 col-md-2 col-xs-3 align-self-center">
            <label htmlFor="consentKey">Key: </label>
          </div>
          <div className="col-lg-11 col-md-10 col-xs-9">
            <input
              id="newsletter-confirm-key"
              name="consentKey"
              type="email"
              className={consentKeyClass}
              placeholder="Your confirmation key"
              required
              onChange={onConsentKeyChange}
              value={props.consentKey}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-2">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    value="newsletterConfirmed"
                    checked={props.isNewsletterConfirmedChecked}
                    onChange={props.newsletterConfirmedChange}
                  />
                </div>
              </div>
              <label className={newsletterConfirmedClass}>
                <small>
                  I confirm that I want to receive the SoCraTes newsletter.
                </small>
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-2">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    value="dataPrivacyConfirmed"
                    checked={props.isDataPrivacyConfirmedChecked}
                    onChange={props.dataPrivacyConfirmedChange}
                  />
                </div>
              </div>
              <label className={dataPrivacyConfirmedClass}>
                <small>
                  <div>
                    I agree that the data provided during the registration for
                    the SoCraTes newsletter (nickname and e-mail) will be
                    collected and processed for the purpose of sending the
                    newsletter.
                  </div>
                  <div>
                    Hint: You can revoke your consent at any time for the future
                    and unsubscribe from the newsletter by using the link in our
                    newsletter or by visiting the{' '}
                    <ExternalLink
                      url="/newsletter-sign-out"
                      target="_blank"
                      title="Newsletter sign out"
                    >
                      signout
                    </ExternalLink>{' '}
                    page.
                  </div>
                  <div>
                    Detailed information on handling user data can be found in
                    our{' '}
                    <ExternalLink
                      url={`/privacy-policy`}
                      target="_blank"
                      title="privacy policy"
                    >
                      {' '}
                      data privacy policy
                    </ExternalLink>
                  </div>
                </small>
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <button
              id="newsletter-confirm-form-button"
              className="btn btn-primary mb-2"
              disabled={props.isDisabled}
              onClick={onSubmit}
            >
              Confirm subscription
            </button>
          </div>
        </div>
      </form>
      {renderMessage()}
    </div>
  );
}
