import axios from 'axios';
import config from '../../../config';
import { Sponsor } from '../sponsoring/sponsoring.types';

const sponsorsApi: string = config.apiEndpoints.sponsors;
const endpoint: string = `${sponsorsApi}/slots/public`;

export type SponsorsApi = {
  getSponsors: () => Promise<Sponsor[]>;
};

const findAllSponsors = async (): Promise<Sponsor[]> => {
  return (await axios.get(`${endpoint}/${config.conference}`)).data;
};

export class Sponsors implements SponsorsApi {
  private _sponsors: Sponsor[];

  public constructor() {
    this._sponsors = [];
  }

  public getSponsors = async () => {
    this._sponsors = await findAllSponsors();
    return this._sponsors.map((s, i) => ({ ...s, index: i }));
  };
}
